import BuyCard from "./BuyCard";
import React from 'react';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { API_DOMAIN } from "../constants";

function Landing() {

  let navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login");
  }

  const kind = [
    {
      title: 'Login',
      description:
        'Click here to login',
      imageUrl: API_DOMAIN + "/images/saadR.jpg",
      time: 1500,
    },
  ];

  return (
    <div style={{
      minHeight: '100vh', width: '100%',
      backgroundImage: `url("${API_DOMAIN}/images/products.jpg")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }}>

      <CssBaseline />
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '12vh',
        fontFamily: 'Futura'
      }} id="header"

      >
        <AppBar style={{ background: 'none', }} elevation={0}>
          <Toolbar style={{
            width: '80%',
            margin: '0 auto',
          }}>
            <h1 style={{ flexGrow: '1', fontSize: '2.6rem', color: '#fff', }}  >
              UZDPart - The Parts People
            </h1>
            <IconButton onClick={goToLogin}>
              <ExitToAppIcon style={{
                color: '#fff',
                fontSize: '2rem',
              }} />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>

      <div style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}

        id="DOWN">
        <div onClick={goToLogin}>
          <BuyCard kind={kind[0]} />
        </div>
      </div>
    </div>
  );
}

export default Landing
