import React, { useState, useRef } from "react";

function ChatBox({ selectedChat, onSendMessage }) {
    const divRef = useRef(null);
    const [currentMessage, setCurrentMessage] = useState("");

    if (!selectedChat) {
        return <div className="chat-box">Select a chat to start messaging</div>;
    }

    const handleSendMessage = () => {

        onSendMessage(currentMessage);
        setCurrentMessage(""); // Clear input after sending
        setTimeout(() => {
            if (divRef.current) {
                divRef.current.scrollTo({
                    top: divRef.current.scrollHeight,
                    behavior: "smooth", // This makes it smooth AF
                });
            }
        }, 120);
    };

    return (
        <div className="chat-box">
            <div ref={divRef} className="messages-container">
                {selectedChat.messages.map((message, index) => (
                    <div key={index} className="message">
                        {message}
                    </div>
                ))}
            </div>
            <div className="input-container">
                <input
                    style={{ width: "260px" }}
                    type="text"
                    placeholder="Type your message"
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
                />
                <button onClick={handleSendMessage}>Send</button>
            </div>
        </div>
    );
}

export default ChatBox;
