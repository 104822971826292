import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// For the material modal
import Box from '@mui/material/Box';
import green_tick from '../assets/images/green_tick.png';
import yellow_error from '../assets/images/yellow_error.png';
import green_loading from '../assets/images/loader-green.gif';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { API_DOMAIN, eventStatusMap } from '../components/constants';

// The MUI alert definition
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Whatsapp() {

    const waStatusCheck = async () => {
        axios.post(API_DOMAIN + "/whatsapp/status", { api_secret: "uzdpart" }).then((res) => {
            if (res.data.success) {
                setWaStatus(res.data.data.status);
                switch (res.data.data.status.status) {
                    case eventStatusMap.qr:
                        document.getElementById('wa_status_img').src = res.data.data.status.qr;
                        document.getElementById('qr_caption').innerHTML = "Scan Now!!"
                        document.getElementById('qr_button').innerHTML = "Tries remaining : " + (5 - res.data.data.status.attempts)
                        setTimeout(() => { waStatusCheck(); }, 3000);
                        break;
                    case eventStatusMap.logged_in:
                        document.getElementById('wa_status_img').src = green_tick
                        document.getElementById('qr_button').innerHTML = "Logout"
                        document.getElementById('qr_caption').innerHTML = "Logged in successfully"
                        break;
                    case eventStatusMap.logged_out:
                        document.getElementById('qr_button').innerHTML = "Request new QR"
                        document.getElementById('qr_caption').innerHTML = "Logged-out, Request new QR"
                        document.getElementById('wa_status_img').src = yellow_error;
                        break;
                    case eventStatusMap.qr_timeout:
                        handleOpenError("QR scanning time-out, Please try again!")
                        document.getElementById('qr_caption').innerHTML = "QR Scanning Timeout"
                        document.getElementById('wa_status_img').src = yellow_error
                        document.getElementById('qr_button').innerHTML = "Request new QR"
                        break;
                    case eventStatusMap.loading_screen: case eventStatusMap.init_whatsapp:
                        document.getElementById('qr_caption').innerHTML = "Checking WA-Bot status"
                        document.getElementById('wa_status_img').src = green_loading
                        document.getElementById('qr_button').innerHTML = "Checking..."
                        setTimeout(() => { waStatusCheck(); }, 3000);
                        break;
                    case eventStatusMap.session_restoring: case eventStatusMap.logging_in:
                        if (res.data.data.status.attempts < 5) {
                            document.getElementById('qr_caption').innerHTML = "Logging in..."
                            document.getElementById('wa_status_img').src = res.data.data.status.qr ? res.data.data.status.qr : green_loading;
                            document.getElementById('qr_button').innerHTML = "processing..."
                            setTimeout(() => { waStatusCheck(); }, 3000);
                        }
                        else {
                            handleOpenError("QR scanning time-out, Please try again!")
                            document.getElementById('qr_caption').innerHTML = "QR Scanning Timeout"
                            document.getElementById('wa_status_img').src = yellow_error
                            document.getElementById('qr_button').innerHTML = "Request new QR"
                        }
                        break;
                    default:
                        document.getElementById('qr_caption').innerHTML = "Click below to request new QR"
                        document.getElementById('wa_status_img').src = yellow_error
                        document.getElementById('qr_button').innerHTML = "Request new QR"
                        setTimeout(() => { waStatusCheck(); }, 3000);
                        break;
                }
            } else {
                document.getElementById('qr_caption').innerHTML = "QR Scanning Timeout"
                document.getElementById('wa_status_img').src = yellow_error
            }
        }).catch((err) => { handleOpenError(err.message) });
    }

    const [waStatus, setWaStatus] = useState([]);
    const [todayQueries, setTodayQueries] = useState(0);
    const [AllTimeQueries, setAllTimeQueries] = useState(0);
    const [WaImg, setWaImg] = useState(green_loading);

    //success snackbar stuff
    const [openSuccessAlert, setSuccessAlert] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const handleOpenSuccess = (reason) => {
        setSuccessMessage(reason);
        setSuccessAlert(true);
    };
    const handleCloseSuccess = () => {
        setSuccessAlert(false);
    };

    //Error snackbar stuff
    const [openErrorAlert, setErrorAlert] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const handleOpenError = (reason) => {
        setErrorMessage(reason);
        setErrorAlert(true);
    };
    const handleCloseError = () => {
        setErrorAlert(false);
    };

    const handleQrRequest = () => {
        switch (document.getElementById('qr_button').innerHTML) {
            case "Request new QR":
                axios.post(API_DOMAIN + "/whatsapp/init_qr", { api_secret: "uzdpart" }).then((res) => {
                    if (res.data.success) {
                        document.getElementById('qr_button').innerHTML = "Requesting new QR..."
                        document.getElementById('wa_status_img').src = green_loading
                        document.getElementById('qr_caption').innerHTML = "Fetching QR..."
                        waStatusCheck();
                    }
                });
                break;
            case "Logout":
                axios.post(API_DOMAIN + "/whatsapp/logout", { api_secret: "uzdpart" }).then((res) => {
                    if (res.data.success) {
                        handleOpenSuccess("Logged-out successfully")
                        document.getElementById('qr_button').innerHTML = "Request new QR"
                        document.getElementById('qr_caption').innerHTML = "Logged-out successfully, Request new QR"
                        document.getElementById('wa_status_img').src = yellow_error;
                    }
                    else {
                        openErrorAlert('Failed to logout!\nTry again later.')
                    }
                });
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        const head = {
            headers: {
                accessToken: localStorage.getItem("accessToken")
            },
            api_secret: "uzdpart"
        }
        waStatusCheck();
    }, []);


    return (
        <>
            <div className="container">
                <div style={{ justifyContent: "space-between", display: "flex" }}>
                    <Box>
                        <table style={{ border: "solid 1px", borderRadius: "5px" }}>
                            <tr>
                                <td>
                                    Total Queries processed today
                                </td>
                                <td>
                                    :
                                </td>
                                <td>
                                    {todayQueries}
                                </td>
                                <td rowSpan={3} style={{ textAlign: "center" }}>
                                    <div id='qr_caption' style={{ padding: "10px" }}>Checking WA-Bot status</div>
                                    <img id='wa_status_img' style={{ padding: "10px", width: "250px", height: "250px", border: "1px solid", borderRadius: "5px" }} src={WaImg} alt='error loading data'></img>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    All-Time Processed Queries
                                </td>
                                <td>
                                    :
                                </td>
                                <td>
                                    {AllTimeQueries}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    <button id='qr_button' className='buttonito' onClick={handleQrRequest} >Checking...</button>
                                </td>
                            </tr>
                        </table></Box>
                </div>

                {/* General purpose success alert */}
                <Snackbar open={openSuccessAlert} autoHideDuration={6000} onClose={handleCloseSuccess}>
                    <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>

                {/* Error message alert */}
                <Snackbar open={openErrorAlert} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="warning" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>

            </div >
        </>
    );

}

export default Whatsapp;