import React, { useRef } from "react";


const ChatList = ({ chats, onSelectChat, selectedChatId }) => {
    const divRef = useRef(null);

    return (
        <div className="chat-list">
            {chats.map((chat) => (
                <div
                    ref={divRef}
                    key={chat.id}
                    className="chat-item"
                    onClick={() => onSelectChat(chat.id, divRef)}
                >
                    
                    <p style={{fontWeight:"600"}}>
                        {chat.name}
                    </p>
                </div>
            ))}
        </div>
    );
}

export default ChatList;
