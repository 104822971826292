import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Assuming you're using react-bootstrap

const settingsOptions = [
    { id: 1, name: "Status Colors" },
    { id: 2, name: "Troubleshoot Whatsapp Bot" },
];

const SettingsModal = ({ open_settings, handleCloseSettingsModal, statuses, save }) => {
    const [selectedOption, setSelectedOption] = useState(settingsOptions[0].id);
    const [statusColors, setStatusColors] = useState([]);

    useEffect(() => {
        if (statuses && statuses.length > 0) {
            setStatusColors(statuses);
        }
    }, [statuses]);

    const handleMenuClick = (id) => {
        setSelectedOption(id);
    };

    const handleSave = () => {
        save(statusColors);
    };

    const handleColorChange = (id, colour) => {
        setStatusColors(prevStatuses =>
            prevStatuses.map(status =>
                status.id === id ? { ...status, colour } : status
            )
        );
    };

    const renderDetails = () => {
        switch (selectedOption) {
            case 1:
                return (
                    <div>
                        <h4>Status Colors</h4>
                        <p>Change the colors of the status</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', overflowY: "auto", height: "300px" }}>
                            <table>
                                <tbody>
                                    {statusColors.map((status) => (
                                        <tr key={status.id}>
                                            <td>
                                                <h5>{status.name}</h5>
                                            </td>
                                            <td>
                                                <input
                                                    type="color"
                                                    value={status.colour}
                                                    onChange={(e) => handleColorChange(status.id, e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h4>Troubleshoot Whatsapp Bot</h4>
                        <p>Only take the higher option after everything else fails.</p>
                        <ol>
                            <div style="display:flex;">
                            <li>Checking the Bot status</li>
                            
                            </div>
                        </ol>
                    </div>
                );
            default:
                return <div>Select a setting from the menu</div>;
        }
    };

    return (
        <Modal show={open_settings} onHide={handleCloseSettingsModal} size="lg" centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ display: 'flex', height: '400px' }}>
                    {/* Menu Pane */}
                    <div style={{ width: '200px', borderRight: '1px solid #ccc', padding: '20px' }}>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {settingsOptions.map(option => (
                                <li key={option.id}
                                    style={{
                                        padding: '10px',
                                        cursor: 'pointer',
                                        backgroundColor: selectedOption === option.id ? '#eee' : 'transparent'
                                    }}
                                    onClick={() => handleMenuClick(option.id)}>
                                    {option.name}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Details Pane */}
                    <div style={{ flex: 1, padding: '20px' }}>
                        {renderDetails()}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseSettingsModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SettingsModal;
