import React, { useContext, useEffect, useCallback, useState, useRef } from 'react';
import { AuthContext } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Divider, MenuItem, Select, Tooltip } from '@mui/material';
import { API_DOMAIN, API_ENDPOINTS } from '../components/constants';
import Modal from '@mui/material/Modal';
import { apiCall } from '../components/Api/Api';
import Checkbox from '@mui/material/Checkbox';
import LoadingComponent from '../components/LoadingComponent/Loading';
import useSnackbar from '../components/Snackbar/UseSnackbar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';

function Dashboard() {

    const style_sup_add = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid lightBlue',
        borderRadius: 6,
        boxShadow: 24,
        p: 4,
        width: "80vw",
        height: "500px",
        display: "flex",
        alignItems: "left",
        flexDirection: "column",
    };

    const { authState } = useContext(AuthContext);

    const api_key = authState.id + authState.username;

    const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();

    let navigate = useNavigate();

    var wa_status = "";
    const [userSet, setUserSet] = useState([]);
    const [userSetMap, setUserSetMap] = useState({});

    const [usertypeSet, setUsertypeSet] = useState([]);

    const [tagSet, setTagSet] = useState([]);
    const [tagData, setTagData] = useState("");
    const [tagSuggestions, setTagSuggestions] = useState([]);
    const [checked, setChecked] = useState(false);

    const [searchTerm, setSearchTerm] = useState("");
    const [supStatus, setSupStatus] = useState("1");
    const tableRef = useRef(null);

    //user formdata
    const [userID, setUserID] = useState(null);
    const [userUsername, setUserUsername] = useState("");
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userMobile, setUserMobile] = useState("");
    const [userLocation, setUserLocation] = useState("");
    const [userCountry, setUserCountry] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [userType, setUserType] = useState("");
    const [watiToken, setWatiToken] = useState("");
    const [targetRange, setTargetRange] = useState([null, null]);
    const [targetAmount, setTargetAmount] = useState(0);


    const [isLoading, setisLoading] = useState(true);

    const [formValid, setformValid] = useState(false);

    const handleUsertypeChange = (e) => {
        setUserType(e.target.value);
    }

    const handleEdit = (id) => {
        handleSupAddOpen();

        setUserID(userSetMap[id].id);
        setUserName(userSetMap[id].name);
        setUserUsername(userSetMap[id].username);
        setUserMobile(userSetMap[id].telephone);
        setUserLocation(userSetMap[id].location);
        setUserCountry(userSetMap[id].country);
        setUserPassword("");
        setUserEmail(userSetMap[id].email);
        setIsAdmin(userSetMap[id].admin);
        setUserType(userSetMap[id].user_type);
        setWatiToken(userSetMap[id].wati_token)
    };
    const deleteTag = async (id) => {
        try {
            const response = await apiCall({
                api_key: "uzdpart",
                id: id
            }, API_ENDPOINTS.delete_supplier_tags, "post");
            if (response.success) {
                setTagSet(response.data);
                SuccessSnackbar("Deleted Tag Successfully");
            }
            else {
                ErrorSnackbar(response.message);
            }
        } catch (error) {
            ErrorSnackbar(error.message);
        }
    };
    const deleteSupplier = async (id, deleteSup) => {
        try {
            const response = await apiCall({ id: id }, "auth/delete_user", "post");
            if (response.success) {
                setUserSet(response.data);
                SuccessSnackbar("Deleted Successfully");
                SuccessSnackbar(response.message);
            }
            else {
                ErrorSnackbar(response.message);
            }
        } catch (error) {
            ErrorSnackbar(error.message);
        }
    };
    const handleTagDelete = async (tag) => {
        if (tag.tagged_supplier_count > 0) {
            const message = `${tag.tagged_supplier_count} Suppliers are tagged with '${tag.name}', Are you sure you want to delete? `;
            if (window.confirm(message)) {
                deleteTag(tag.id);
            }
        }
        else {
            deleteTag(tag.id);
        }
    };
    const handleSupplierDelete = async (user) => {

        if (window.confirm(`Are you sure to delete user ${user.username} (${user.name})`)) {
            deleteSupplier(user.id);
        }
    };
    const [open_sup_add, setSupModal] = useState(false);
    const handleSupAddOpen = () => {
        setSupModal(true);
    };
    const handleSupAddClose = () => {
        setSupModal(false);
        clearUserForm();
        reload_user_data();
    };

    const resetInput = (id) => {
        switch (id) {
            case 'search_supplier':
                setSearchTerm("");
                reload_user_data("", supStatus)
                break;
        }
    };
    const reload_user_data = async (value = "", supStatus) => {
        try {
            setisLoading(true);
            const response = await apiCall({ api_key: "uzdpart", search: value, supStatus }, API_ENDPOINTS.get_all_users, "get");
            if (response.success) {
                setisLoading(false);
                setUserSet(response.data);
                console.log(JSON.stringify(response.data))
                var dataset = {};
                response.data.forEach(dataValue => {
                    dataset[dataValue.id] = dataValue;
                });
                setUserSetMap(dataset);
                SuccessSnackbar("Fetched " + response.data.length + " Search result");
            }
            else {
                setisLoading(false);
                ErrorSnackbar(response.message);
            }
        } catch (error) {
            setisLoading(false);
            ErrorSnackbar(error.message);
        }
    };
    const reload_supplier_tags = async () => {
        axios.post(API_DOMAIN + "/whatsapp/get_supplier_tags", { api_key: "uzdpart" }).then((res) => {
            if (res.data.success) {
                setisLoading(false);
                setTagSet(res.data.data);
                var tagSetTemp = res.data.data.map((item, index) => ({
                    value: item.id,
                    label: item.name
                }));
                setTagSuggestions(tagSetTemp);
            }
        }).catch((error) => {
            setisLoading(false);
            ErrorSnackbar(error.message);
        });
    };
    const handleKeyPress = (e, id) => {
        const value = e.target.value;
        setSearchTerm(value)
        if (e.key === 'Enter') {
            switch (id) {
                case 'search_supplier':
                    reload_user_data(value, supStatus);
                    break;
            }
        }
    };
    const reload_usertypes = () => {
        axios.post(API_DOMAIN + "/usertypes/get_user_types", { api_key: "uzdpart" }).then((res) => {
            if (res.data.success) {
                setUsertypeSet(res.data.data);
            }
        }).catch((error) => {
            ErrorSnackbar(error.message);
        });
    }
    const onSupMobChange = async (value, id) => {
        var flag = 0;
        setUserMobile(value);
        setTimeout(async () => {
            try {
                var number = value.replace(/[^0-9]/g, '');
                if (number.length >= 10) {
                    flag = 1;
                }
                else if (number.startsWith('971') && number.length == 12) {
                    flag = 1;
                }
                else {
                    wa_status = "-";
                    document.getElementById("wa_valid").style = "color:black;text-align:right;padding-right:10px;";
                }
                if (flag == 1) {
                    const response = await apiCall({ api_secret: "uzdpart", phone: number, id }, API_ENDPOINTS.get_wa_status, "post");
                    if (!response.success && response.data.length > 0) {
                        wa_status = "Supplier Already Exists (" + response.data[0].name + ")";
                        setformValid(false);
                        document.getElementById("wa_valid").style = "color:red;";
                    }
                    if (response.success && response.data.status == 200) {
                        wa_status = "YES";
                        setformValid(true);
                        document.getElementById("wa_valid").style = "color:green;text-align:right;padding-right:10px;";
                    }
                    if (response.success && response.data.status == 404) {
                        wa_status = "NO";
                        setformValid(false);
                        document.getElementById("wa_valid").style = "color:red;text-align:right;padding-right:10px;";
                    }
                }
                document.getElementById("wa_valid").innerHTML = wa_status;
            } catch (error) {
                ErrorSnackbar(error.message);
            }
        }, 0);
    };
    useEffect(() => {
        reload_user_data();
        reload_usertypes();

        reload_supplier_tags();
    }, []);

    const handleSaveClick = () => {
        if (!userUsername) {
            alert("Username is required");
            return;
        }
        if (!userName) {
            alert("Name is required");
            return;
        }
        if (!userEmail || !/\S+@\S+\.\S+/.test(userEmail)) {
            alert("Valid email is required");
            return;
        }
        if (!userMobile || !/^\d{10,15}$/.test(userMobile)) {
            alert("Valid mobile number is required");
            return;
        }
        if (!userPassword || userPassword.length < 5) {
            alert("Password is required and should be at least 6 characters long");
            return;
        }
        if (!userLocation) {
            alert("Location is required");
            return;
        }
        if (!userCountry) {
            alert("Country is required");
            return;
        }
        if (isAdmin) {
            if (!window.confirm("Are you sure you want to make this user an admin?")) {
                return;
            }
        }
        const data = {
            id: userID,
            name: userName,
            username: userUsername,
            password: userPassword,
            email: userEmail,
            telephone: userMobile,
            location: userLocation,
            country: userCountry,
            admin: isAdmin,
            user_type: userType,
            wati_token: watiToken,
            moredetails: { targetRange, targetAmount }
        }
        console.log("Data: ", JSON.stringify(data));

        axios.post(API_DOMAIN + "/auth/register", data).then((res) => {
            if (res.data.error) {
                ErrorSnackbar(res.data.error);
            }
            else {
                if (userID)
                    SuccessSnackbar("User updated successfully!");
                else
                    SuccessSnackbar("User added successfully");

                handleSupAddClose();

            }
        });
    }
    const shortcutsItems = [
        {
            label: "This Week",
            getValue: () => {
                const today = dayjs();
                return [today.startOf("week"), today.endOf("week")];
            },
        },
        {
            label: "Last Week",
            getValue: () => {
                const today = dayjs();
                const prevWeek = today.subtract(7, "day");
                return [prevWeek.startOf("week"), prevWeek.endOf("week")];
            },
        },
        {
            label: "Last 7 Days",
            getValue: () => {
                const today = dayjs();
                return [today.subtract(7, "day"), today];
            },
        },
        {
            label: "This Month",
            getValue: () => {
                const today = dayjs();
                return [today.startOf("month"), today.endOf("month")];
            },
        },
        {
            label: "Next Month",
            getValue: () => {
                const today = dayjs();
                const startOfNextMonth = today.endOf("month").add(1, "day");
                return [startOfNextMonth, startOfNextMonth.endOf("month")];
            },
        },
        {
            label: "Today",
            getValue: () => {
                const today = dayjs();
                return [today.startOf("day"), today.endOf("day")];
            },
        },
        { label: "Reset", getValue: () => [null, null] },
    ];
    const clearUserForm = () => {
        setUserID(null);
        setUserName(null);
        setUserUsername("");
        setUserMobile("");
        setUserLocation("");
        setUserCountry("");
        setUserPassword("");
        setUserEmail("");
        setIsAdmin(false);
        setUserType("");
        setWatiToken("");
    }
    const onSearchDateChange = (dates) => {
        setTargetRange(dates);
    };
    return (
        <div>
            <div style={{ display: 'flex' }}>
                <div style={{ marginLeft: "auto" }}>
                    <button style={{ width: "120px", backgroundColor: "rgb(210,200,110)", cursor: "pointer" }} onClick={(e) => handleSupAddOpen()}>Add User</button>
                </div>
            </div>
            <div className="outer-container">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <Tooltip title="Type in the search term and press enter for results, clear the value using the x button to reset the search.">
                            <input value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} id='search_supplier' style={{ maxWidth: "100%", marginTop: "2px", margin: "2px", borderRadius: "5px" }} onKeyPress={(e) => handleKeyPress(e, 'search_supplier')} placeholder='Search Users'>
                            </input>
                            <span style={{ position: "absolute", transform: "translateY(50px)", transform: "translateX(-20px)", cursor: "pointer", fontSize: "13px", color: "gray", marginTop: "4px", }} onClick={() => resetInput('search_supplier')}>&#10005;</span>
                        </Tooltip>
                        <select value={supStatus} style={{ marginLeft: "0px" }}>
                            <option value="all">All</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </div>
                    <div style={{ fontSize: "22px" }}>USERS</div>
                    <div style={{ fontSize: "22px" }}>Total Users : {userSet.length}</div>
                </div>
                <div className="table-container">
                    <table className="supplier-table">
                        <thead>
                            <tr>
                                <th style={{ width: "35px" }}>SlNo</th>
                                <th>Name</th>
                                <th>Username</th>
                                <th>Mobile</th>
                                <th>Location</th>
                                <th>User Type</th>
                                <th>WATI Token</th>
                                <th>Target Details</th>
                                <th>Is-Admin?</th>
                                <th style={{ width: "120px" }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userSet.map((user_data, index) => (
                                <tr key={user_data.id}>
                                    <td>{index + 1}</td>
                                    <td>{user_data.name}</td>
                                    <td>{user_data.username}</td>
                                    <td>{user_data.telephone}</td>
                                    <td>{user_data.location}</td>
                                    <td>{user_data.user_type}</td>
                                    <td>{user_data.wati_token ? "Added OK ✅" : "Not Added ❌"}</td>
                                    <td>
                                        <div>
                                            <h7>
                                                {user_data?.moredetails?.targetRange || ""}
                                            </h7>
                                            <br />
                                            <em style={{ color: "grey" }}>
                                                {user_data?.moredetails?.targetAmount || ""}
                                            </em>
                                        </div>
                                    </td>
                                    <td >{user_data.admin ? 'Yes' : 'No'}</td>
                                    <td style={{ display: "flex" }}>
                                        <button onClick={() => handleEdit(user_data.id)} style={{ width: "40%" }}>✏️</button>
                                        <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                        <button onClick={() => handleSupplierDelete(user_data)} style={{ width: "40%" }}>🗑️</button>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div >


            {/* modal to add/edit new supplier */}
            <Modal
                open={open_sup_add}
                onClose={handleSupAddClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{ onClick: (event) => event.stopPropagation() }}
                disableEscapeKeyDown
            >
                <Box sx={style_sup_add}>
                    <h2 id="userAddModalTitle" style={{ textAlign: "center", marginTop: "0px" }}>Add New User</h2>
                    <div style={{ height: "480px", border: "1px black solid", borderRadius: "5px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "0px 20px 0px 20px" }}>
                            <table className='user_add_table' style={{ margin: "5px", border: "1px black dashed", borderRadius: "5px", padding: "0px 10px 0px 10px" }}>
                                <tbody>
                                    <tr>
                                        <td>Username<span className="required">*</span></td>
                                        <td>
                                            User Type
                                            <Select
                                                labelId="user-type-label"
                                                id="user-type-select"
                                                value={userType}
                                                label="User Type"
                                                onChange={handleUsertypeChange}
                                            >
                                                {usertypeSet.map((type) => (
                                                    <MenuItem key={type.id} value={type.user_type}>
                                                        {type.user_type}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><input type="text" value={userUsername} onChange={(e) => setUserUsername(e.target.value)} required /></td>
                                        <td>
                                            Is Admin?
                                            <Checkbox
                                                checked={isAdmin}
                                                onChange={(e) => setIsAdmin(e.target.checked)}
                                                sx={{ cursor: "pointer" }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Name<span className="required">*</span></td>
                                        <td>Country<input type="text" value={userCountry} onChange={(e) => setUserCountry(e.target.value)} /></td>
                                    </tr>
                                    <tr>
                                        <td><input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} required /></td>
                                    </tr>
                                    <tr>
                                        <td>Password<span className="required">*</span></td>
                                        <td>WATI Token</td>
                                    </tr>
                                    <tr>
                                        <td><input type="password" value={userPassword} onChange={(e) => setUserPassword(e.target.value)} required /></td>
                                        <td><input value={watiToken} onChange={(e) => setWatiToken(e.target.value)} /></td>
                                    </tr>
                                    <tr>
                                        <td>Email<span className="required">*</span></td>
                                    </tr>
                                    <tr>
                                        <td><input type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} required /></td>
                                    </tr>
                                    <tr>
                                        <td>Mobile Number<span className="required">*</span></td>
                                    </tr>
                                    <tr>
                                        <Tooltip title="Remember to put the country code without plus(+) in the beginning like 971xxxxxxxx.">
                                            <td><input type="number" value={userMobile} onChange={(e) => setUserMobile(e.target.value)} required /></td>
                                        </Tooltip>
                                    </tr>
                                    <tr>
                                        <td>Location</td>
                                    </tr>
                                    <tr>
                                        <td><input type="text" value={userLocation} onChange={(e) => setUserLocation(e.target.value)} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className='user_add_table' style={{ margin: "5px", border: "1px black dashed", borderRadius: "5px", padding: "0px 10px 0px 0px" }}>
                                <tbody>
                                    <tr>
                                        <td>Time Range</td>
                                        <td>:</td>
                                        <td>
                                            <div style={{ fontSize: "8px" }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={["SingleInputDateRangeField"]}>
                                                        <DateRangePicker
                                                            slotProps={{
                                                                shortcuts: {
                                                                    items: shortcutsItems,
                                                                },
                                                                actionBar: { actions: [] },
                                                            }}
                                                            slots={{ field: SingleInputDateRangeField }}
                                                            name="allowedRange"
                                                            format="YYYY-MM-DD"
                                                            onChange={onSearchDateChange}
                                                            className="daterangepicker_search"
                                                            value={targetRange}
                                                            defaultValue={targetRange}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Amount</td>
                                        <td>:</td>
                                        <td><input type="number" value={targetAmount} onChange={(e) => setTargetAmount(e.target.value)} /></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <button id='modal_close_button' className='buttonito' style={{ width: "170px", backgroundColor: "red" }} onClick={handleSupAddClose}>Close</button>
                            <button id='modal_save_button' className='buttonito' style={{ width: "170px", backgroundColor: "green" }} onClick={handleSaveClick}>Save</button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <ToastContainer />


            <LoadingComponent isLoading={isLoading} />
        </div >

    );

}

export default Dashboard
