import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

const useLogout = () => {
  let navigate = useNavigate();
  const { setAuthState } = useContext(AuthContext);
  const [openDialogue, setOpenDialogue] = useState(false);

  const handledialogueOpen = () => {
    setOpenDialogue(true);
  };

  const handledialogueClose = () => {
    setOpenDialogue(false);
  };

  const logout_now = () => {
    localStorage.removeItem("accessToken");
    setAuthState({
      username: "",
      id: 0,
      status: false
    });
    setOpenDialogue(false);
    navigate('/');
  };

  const LogoutDialog = () => (
    <Dialog
      open={openDialogue}
      onClose={handledialogueClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Futura' }}>
        {"Are you sure you want to Log-out?"}
      </DialogTitle>
      <DialogActions>
        <button className="buttonitoReverse" onClick={handledialogueClose}>Cancel</button>
        <button className="buttonito" onClick={logout_now} autoFocus>Confirm</button>
      </DialogActions>
    </Dialog>
  );

  return {
    handledialogueOpen,
    LogoutDialog
  };
};

export default useLogout;
