import React, { useEffect, useCallback, useState, useRef } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Box from '@mui/material/Box';
import green_tick from '../assets/images/green_tick.png';
import yellow_error from '../assets/images/yellow_error.png';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { DeleteOutlineRounded } from '@mui/icons-material';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { API_DOMAIN, eventStatusMap } from '../components/constants';
import { useNavigate } from 'react-router-dom';
import whatsapp_logo from '../assets/images/whatsapp-96.png';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import EditIcon from '@mui/icons-material/Edit';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ReactTags } from 'react-tag-autocomplete'
import styled from 'styled-components';
import * as XLSX from 'xlsx';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ImportExport from '@mui/icons-material/ImportExport';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import socket from '../components/socket';
import LoadingComponent from '../components/LoadingComponent/Loading';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
var moment = require('moment');

// The MUI alert definition
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// The style for the modals
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid lightBlue',
    borderRadius: 6,
    boxShadow: 24,
    p: 4,
    width: "800px",
    height: "470px",
    display: "flex",
    alignItems: "left",
    flexDirection: "column",
};
const style_wa = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid lightBlue',
    borderRadius: 6,
    boxShadow: 24,
    p: 4,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
};
const style_region = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "35%",
    bgcolor: 'background.paper',
    border: '2px solid lightBlue',
    borderRadius: 6,
    boxShadow: 24,
    p: 4,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
};

const CustomSpan = styled.span`
  font-size: 22px;
  cursor: pointer;
  
  &:hover {
    background-color: rgb(220, 220, 220); /* Change the color on hover */
    border-radius : 350px;
  }
`;
var phoneToNameMap = [];
var supplierIdToName = [];

// Displaying the Users to the admin and their information
function Queryset() {
    let navigate = useNavigate();

    // The basic datasets
    const [querySet, setquerySet] = useState([]);
    const [makeSet, setmakeSet] = useState([]);
    const [brandSet, setbrandSet] = useState([]);
    const [modelSet, setModelSet] = useState([]);
    const [supplierSet, setsupplierSet] = useState([]);
    const [regionDataSet, setRegionDataSet] = useState([]);
    const [cus_name_set, set_cus_name_set] = useState([]);
    const [queryStatusSet, setQueryStatusSet] = useState([]);
    const [costPriceSet, setCostPriceSet] = useState([]);

    const [isLoading, setisLoading] = useState(true);


    //Search parameters
    const [searchStatus, setSearchStatus] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [startDate, setStartDate] = useState(new Date(moment().format('YYYY-MM-DD HH:mm:ss')));
    const [endDate, setEndDate] = useState(new Date(moment().format('YYYY-MM-DD HH:mm:ss')));


    //Custom modal to add stuff
    const [modalHeading, setModalHeading] = useState("");
    const [modal_data, set_modal_data] = useState("");

    //form data
    const [make_data, set_make_data] = useState([]);
    const [brand_data, set_brand_data] = useState(null);
    const [model_data, set_model_data] = useState(null);
    const [model_details_data, set_model_details_data] = useState("");
    const [key_to_reset, set_key_to_reset] = useState(null);
    const [cus_name, set_cus_name] = useState("");
    const [year_data, set_year_data] = useState(null);
    const [chassisno_data, set_chassisno_data] = useState('');
    const [cylinder_data, set_cylinder_data] = useState('');
    const [bodypart_data, set_bodypart_data] = useState('');
    const [phone_data, set_phone_data] = useState('');
    const [region_data, set_region_data] = useState('');
    const [file_data, set_file_data] = useState([]);
    const [WhatsappStatus, setWhatsappStatus] = useState([]);
    const [edit, setEdit] = useState(false);
    


    //misc data
    const [waStatus, setwaStatus] = useState({ success: [], failed: [] });

    //references
    const file_DataRef = useRef(null);
    const queryDateRef = useRef(null);

    //Tab Focus to know if to send desktop notifications
    const [isTabFocused, setIsTabFocused] = useState(document.visibilityState === 'visible');
    const isTabFocusedRef = useRef(isTabFocused);




    //success snackbar stuff
    const [openSuccessAlert, setSuccessAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const handleOpenSuccess = (reason) => {
        setSuccessMessage(reason);
        setSuccessAlert(true);
    };
    const handleCloseSuccess = () => {
        setSuccessMessage("");
        setSuccessAlert(false);
    };

    //Error snackbar stuff
    const [openErrorAlert, setErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const handleOpenError = (reason) => {
        setErrorMessage(reason);
        setErrorAlert(true);
    };
    const handleCloseError = () => {
        setErrorMessage("");
        setErrorAlert(false);
    };




    // These are for the Modals
    //Query adding modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        if (WhatsappStatus.status === "logged_in") {
            reload_customers();
            setOpen(true)
        }
        else
            alert("Please resolve WhatsApp Bot issues before making a query.");
    };
    const handleClose = () => {
        reloadQuerySet();
        document.getElementById("sup_count").style.display = "none";
        set_make_data([]);
        setModelSet([]);
        set_brand_data(null);
        set_model_data(null);
        set_year_data(null);
        set_model_details_data("");
        set_chassisno_data("");
        set_cylinder_data("");
        set_bodypart_data("");
        set_phone_data("");
        set_region_data("");
        set_file_data(null);
        setEdit(false);
        set_cus_name("");
        setTimeout(() => {
            if (file_DataRef.current) {
                file_DataRef.current.disabled = true;
            }
        }, 0);
        setOpen(false);
    };

    //Whatsapp Bot Query sent status viewing modal
    const [open_stat, setOpenStat] = useState(false);
    const handleOpenStat = (value) => {
        var wa_stat = value.moredetails;

        // Add name to failed entries
        wa_stat.failed = wa_stat.failed.map(entry => ({
            ...entry,
            name: phoneToNameMap[entry.number]
        }));

        // Convert success entries to objects with name and number
        wa_stat.success = wa_stat.success.map(number => ({
            number: number,
            name: phoneToNameMap[number]
        }));
        setwaStatus(wa_stat);
        setOpenStat(true);
    };
    const handleCloseStat = () => {
        setOpenStat(false);
    };



    //Adding stuff to DB modal
    const [open_add_modal, setAddModalState] = useState(false);
    const handleOpenAddModal = (title) => {
        if (title == "Add New Model" && (brand_data == null || brandSet.some(element => element['label'] == "" ? false : element['label'] == brand_data))) {
            handleOpenError("Please select a 'Make' first, to add a new model")
            return;
        }
        else {
            setModalHeading(title == "Add New Model" ? "Add New '" + brand_data.label + "' Model" : title);
            set_modal_data("");
            setAddModalState(true);
        }
    };
    const handleCloseAddModal = () => {
        set_modal_data("");
        setModalHeading("")
        setAddModalState(false);
    };


    //handling onclicks
    const handling_setquerySet = (data) => {
        const datasetMap = {};
        const initialPriceValues = {};

        data.forEach(dataset => {
            dataset.price_percentage = (((dataset.price_given - dataset.best_price_received - dataset.delivery_cost ?? 0) / dataset.best_price_received) * 100).toFixed(2) === 'NaN' ? 0 : (((dataset.price_given - dataset.best_price_received - dataset.delivery_cost ?? 0) / dataset.best_price_received) * 100).toFixed(2);

            datasetMap[dataset.query_id] = dataset;

            initialPriceValues[dataset.query_id] = {
                best_price_received: dataset.best_price_received || 0,
                price_percentage: dataset.price_percentage || 0,
                price_given: dataset.price_given || 0,
                status: dataset.status,
                delivery_cost: dataset.delivery_cost ? { DeliveryCost: dataset.delivery_cost } : { DeliveryCost: 0, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0 },
                statusValue: dataset.status == 'postponed' ? new Date(dataset?.moredetails?.statusValue) : dataset?.moredetails?.statusValue || null,
            };
        });

        setquerySet(data);
        setQueryDataMap(datasetMap);
        setPriceValues(initialPriceValues);
    };
    const handleInputChange = (queryId, field, value) => {
        setPriceValues({
            ...priceValues,
            [queryId]: {
                ...priceValues[queryId],
                [field]: value
            }
        });
    };
    const handleFileChange = (event) => {
        const files = event.target.files;
        var final_files = [];

        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                final_files.push(base64String);
            };
            reader.readAsDataURL(files[i]);

        }
        set_file_data(final_files);
    };
    const handleSubmit = () => {
        //split the below if condition to check for each field
        if (!make_data) {
            alert('Please Select a Make');
            return;
        }
        if (!model_data) {
            alert('Please Select a Model');
            return;
        }
        if (!bodypart_data) {
            alert('Please Enter a PartName');
            return;
        }
        if ((edit ? false : !file_data)) {
            alert('Please Select an Image');
            return;
        }
        if (!year_data) {
            alert('Please Select a Year');
            return;
        }
        if (!brand_data) {
            alert('Please Select a Brand');
            return;
        }
        if (chassisno_data) {
            if (chassisno_data.length < 17) {
                alert('Please Enter a Valid Chassis Number');
                return;
            }
        }

        // Proceed with your form submission or further processing
        const formData = {};
        formData.make = make_data;
        formData.brand = brand_data;
        formData.model = model_data;
        formData.model_details = model_details_data.toString().trim();
        formData.year = year_data ? moment(year_data).format("YYYY") : "";
        formData.chassisNo = chassisno_data.toString().trim();
        formData.cylinders = cylinder_data.toString().trim();
        formData.bodypart = bodypart_data.toString().trim();
        formData.phone = phone_data.toString().trim();
        formData.region = region_data.toString().trim();
        formData.cus_name = cus_name.toString().trim();
        formData.image = file_data;
        formData.date = new Date().toISOString().substring(0, 10);
        formData.parallel = parallel;
        formData.edit = edit;

        // document.getElementById("modal_close_button").disabled = true;
        // document.getElementById("modal_send_button").disabled = true;
        // document.getElementById("modal_send_button").innerHTML = 'Processing...';


        axios.post(API_DOMAIN + "/whatsapp/add_querysetV2", { formData, api_secret: "uzdpart" })
            .then((res) => {
                if (res.data.success) {

                    document.getElementById("modal_close_button").disabled = false;
                    document.getElementById("modal_send_button").disabled = false;


                    if (isTabFocusedRef.current) {
                        handleOpenSuccess("Query ID " + res.data.data.query_id + " processed successfully!")
                    }
                    else {
                        showNotification("Query (" + res.data.data.query_id + ") Succeeded", "Query with ID " + res.data.data.query_id + " has been processed successfully!", null)
                    }
                    handleClose();
                }
                else {
                    if (isTabFocusedRef.current) {
                        handleOpenError("Query ID " + res.data.data.query_id + " Failed to Process!\nPlease try again")
                        handleOpenError(res.data.message);
                    }
                    else {
                        showNotification("Query (" + res.data.data.query_id + ") Failed", "Query with ID " + res.data.data.query_id + " has Failed to process!\nPlease try again later.", null)
                    }
                    document.getElementById("modal_close_button").disabled = false;
                    document.getElementById("modal_send_button").disabled = false;
                    document.getElementById("modal_send_button").innerHTML = "Try Again...";
                }
            })
            .catch((error) => {
                handleOpenError("Failed to process Queryset! Try again later\n", error.message)
            });
    };
    const handleQueryEdit = (query_id, phone) => {
        setEdit(true);
        handleOpen();
        if (phone)
            setTimeout(() => {
                handleCusMobChange(phone)
            }, 0);
        set_make_data([]);
        setModelSet([]);
        set_make_data(queryDataMap[query_id].sup_set);
        set_brand_data(queryDataMap[query_id].make);
        load_models(queryDataMap[query_id].make);
        set_model_data(queryDataMap[query_id].model);
        set_key_to_reset(moment().format("YYYY-MM-DD HH:mm:ss"))
        set_model_details_data(queryDataMap[query_id].model_details);
        set_bodypart_data(queryDataMap[query_id].body_part);
        set_year_data(new Date(moment(queryDataMap[query_id].year, "YYYY").format("YYYY-MM-DD HH:mm:ss")));
        set_chassisno_data(queryDataMap[query_id].chassis_no);
        set_cylinder_data(queryDataMap[query_id].cylinder);
        set_region_data(queryDataMap[query_id].region);
        set_cus_name(queryDataMap[query_id].cus_name);
        set_phone_data(queryDataMap[query_id].phone);
        set_file_data(queryDataMap[query_id].image);
        setTimeout(() => {
            if (file_DataRef.current) {
                file_DataRef.current.disabled = true;
            }
        }, 0);

    };
    const handleFocus = (event) => {
        event.target.select();
    };
    const handleKeyPress = (event, queryId, field) => {
        if (event.key === 'Enter') {
            const value = event.target.value;
            if (field === 'best_price_received') {
                handleQueryUpdate('', false, '', false, '', false, value, true, queryId, '', false, false);
            } else if (field === 'price_given') {
                handleQueryUpdate('', false, '', false, value, true, '', false, queryId, '', false, false);
            }
        }
    };
    const handleQueryUpdate = (supplier, bSupplier, status, bStatus, price_given, bPrice_given, best_price_received, bBest_price_received, query_id, deliveryCost, bdeliveryCost, mdetails, bmdetails, disable) => {
        document.getElementById('price_percentage' + query_id).disabled = true
        document.getElementById('delivery_cost' + query_id).disabled = true
        document.getElementById('price_given' + query_id).disabled = true
        if (bStatus && status == "Postponed")
            mdetails.statusValue = null;
        axios.post(API_DOMAIN + "/whatsapp/update_queryset", { supplier, bSupplier, status, bStatus, price_given, bPrice_given, best_price_received, bBest_price_received, query_id, deliveryCost, bdeliveryCost, mdetails, bmdetails, disable })
            .then((res) => {
                document.getElementById('price_percentage' + query_id).disabled = false
                document.getElementById('delivery_cost' + query_id).disabled = false
                document.getElementById('price_given' + query_id).disabled = false
                handleOpenSuccess("Updated Queryset successfully!")
                reloadQuerySet();
            })
            .catch((error) => {
                document.getElementById('price_percentage' + query_id).disabled = false
                document.getElementById('delivery_cost' + query_id).disabled = false
                document.getElementById('price_given' + query_id).disabled = false
                // Handle error if needed
            });
    };
    const handleUnsend = (query_id) => {
        axios.post(API_DOMAIN + "/whatsapp/delete_messages", { api_secret: "uzdpart", query_id })
            .then((res) => {
                if (res.data.success) {
                    handleOpenSuccess("Unsent Queryset successfully!")
                }
                else {
                    handleOpenError("Unsent Queryset Failed!\nPlease try again later!");
                }
                reloadQuerySet();
            })
            .catch((error) => {
                // Handle error if needed
            });
    }
    const handleWAstatus = () => {
        navigate('/whatsapp');
    };
    const handleParallelChange = (e) => {
        const checked = e.target.checked;
        setIsChecked(checked);
        setParallel(checked);
    };
    const handleMakeChange = (value) => {
        if (value.length > 0) {
            axios.post(API_DOMAIN + "/whatsapp/get_suppliers", { make: value, api_secret: "uzdpart" })
                .then((res) => {
                    if (res.data.success) {
                        document.getElementById("sup_count").style.display = "block";
                        document.getElementById("sup_count").innerHTML = "Found " + res.data.data.length + " Suppliers with possible availability!"
                        if (res.data.data.length > 0 && res.data.data.length < 100) {
                            document.getElementById("parallel_send").style.display = "block";
                            document.getElementById("parallel_send").checked = "true";
                        }
                        else {
                            document.getElementById("parallel_send").style.display = "none";
                            document.getElementById("parallel_send").checked = "false";
                            handleParallelChange({
                                target: {
                                    checked: false
                                }
                            })
                        }
                    }
                    // axios.post(API_DOMAIN + "/whatsapp/get_prevdata_make", { make: value, api_secret: "uzdpart" })
                    //     .then((res) => {
                    //         if (res.data.success) { }

                    //     });
                });
        }
        else {
            if (document.getElementById("parallel_send")) {
                document.getElementById("parallel_send").style.display = "none";
                document.getElementById("parallel_send").checked = "false";
                document.getElementById("sup_count").style.display = "none";
                handleParallelChange({ target: { checked: false } })
            }
        }
    };
    const handleBrandChange = async (value) => {
        set_model_data(null);
        set_key_to_reset(moment().format("YYYY-MM-DD HH:mm:ss"))
        set_brand_data(value);
        if (value != "" && value != null && brandSet.some(element => element['label'] == value?.label)) {
            load_models(value.label);
        }
        else {
            setModelSet([]);
        }
    };
    const handle_model_change = async (value) => {
        set_model_data(value);
    };
    const handleCusNameChange = (cusId) => {
        set_cus_name(cusId);
        var mobile = "";
        for (let i = 0; i < cus_name_set.length; i++) {
            if (cus_name_set[i].customer_id == cusId) {
                mobile = cus_name_set[i].customer_mobile;
                break;
            }
        }
        set_phone_data(mobile);
    };
    const handleCusMobChange = (phone) => {
        for (let i = 0; i < cus_name_set.length; i++) {
            if (cus_name_set[i].customer_mobile == phone) {
                set_cus_name(cus_name_set[i].customer_id);
                break;
            }
        }
    }
    const handleExcelClick = () => {
        generateExcel(querySet);
    };
    const handlePdfClick = () => {
        generatePDF(querySet);
    };
    const resetInput = (id) => {
        switch (id) {
            case 'search_field':
                setSearchTerm("");
                break;
        }
    };
    const handleModalAdd = (e, title) => {
        switch (title) {
            case "Add New Region":
                if (modal_data.length == 0) {
                    handleOpenError("Please Enter a Valid Region Name");
                }
                else {
                    axios.post(API_DOMAIN + "/whatsapp/region_add_update", { region: modal_data }).then((res) => {
                        if (res.data.success) {
                            setRegionDataSet(res.data.data);
                            set_region_data(modal_data);
                            handleOpenSuccess("Region added successfully");
                            handleCloseAddModal();
                        }
                    });
                    break;
                }
            case "Add New Make":
                if (modal_data.length == 0) {
                    handleOpenError("Please Enter a Valid Make Name");
                }
                else {
                    axios.post(API_DOMAIN + "/whatsapp/add_makes_v2", { name: modal_data }).then((res) => {
                        if (res.data.success) {
                            const makeSetTemp = res.data.data.map(item => ({
                                id: item.id,
                                label: item.name
                            }));
                            setbrandSet(makeSetTemp);
                            set_brand_data(modal_data);
                            handleOpenSuccess("Make added successfully");
                            handleCloseAddModal();
                        }
                        else {
                            handleOpenError(res.data.message);
                        }
                    });
                    break;
                }
            case "Add New '" + brand_data.label + "' Model":
                if (modal_data.length == 0) {
                    handleOpenError("Please Enter a Valid Model Name");
                }
                else {
                    axios.post(API_DOMAIN + "/whatsapp/add_model_v2", { make: brand_data, model: modal_data }).then((res) => {
                        if (res.data.success) {
                            const makeSetTemp = res.data.data.map(item => ({
                                id: item.id,
                                label: item.name
                            }));
                            setModelSet(makeSetTemp);
                            set_model_data(modal_data);
                            handleOpenSuccess("Model added successfully");
                            handleCloseAddModal();
                        }
                        else {
                            handleOpenError(res.data.message);
                        }
                    });
                    break;
                }
        }

    };
    const handleRegionChange = (value) => {
        set_region_data(value);
    };
    const onSearchDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const onAdd = useCallback(
        (newTag) => {
            set_make_data([...make_data, newTag])
        },
        [make_data]
    );
    const onDelete = useCallback(
        (tagIndex) => {
            set_make_data(make_data.filter((_, i) => i !== tagIndex))
        },
        [make_data]
    );
    async function pasteClipboardContent(id) {
        const clipboardText = await navigator.clipboard.readText();
        switch (id) {
            case "model_value":
                set_model_details_data(clipboardText);
                break;
            case "part_value":
                set_bodypart_data(clipboardText);
                break;
            case "chassis_value":
                set_chassisno_data(clipboardText);
                break;
            case "phone_value":
                set_phone_data(clipboardText);
                break;
            case "region_value":
                set_region_data(clipboardText);
                break;
        }
    };


    //miscellaneous functions
    const showNotification = (main, body, logo) => {
        if (Notification.permission === 'granted') {
            new Notification(main ? main : 'Hello!', {
                body: body ? body : 'This is a system-level notification.',
                icon: logo ? logo : 'https://www.uzdpart.com/wp-content/themes/uzdpart-child/assets/images/logo.PNG'
            });
        } else {
            console.log('Notification permission not granted.');
        }
    };
    const generatePDF = (data) => {
        console.log("data =", data);
        if (data.length > 0) {
            handleOpenSuccess(`Preparing PDF from ${startDate} to ${endDate} ${searchStatus != "" ? "with status " + searchStatus : "."} contains ${data.length} rows.`)
            const docDefinition = {
                pageSize: "A3",
                pageOrientation: 'landscape',
                content: [
                    { text: `Results between (${moment(startDate).format("YYYY-MM-DD")} ~ ${moment(endDate).format("YYYY-MM-DD")}), Status : ${searchStatus ? searchStatus : "<All Statuses>"}, Total Rows : ${data.length}`, margin: [0, 20, 0, 8] },
                    {
                        style: 'tableExample',
                        table: {
                            body: [
                                [{ text: 'SlNo', style: 'tableHeader' }, { text: 'Query ID', style: 'tableHeader' }, { text: 'Date', style: 'tableHeader' }, { text: 'Make', style: 'tableHeader' }, { text: 'Model', style: 'tableHeader' }, { text: 'Year', style: 'tableHeader' }, { text: 'Part', style: 'tableHeader' }, { text: 'Customer Name', style: 'tableHeader' }, { text: 'Phone', style: 'tableHeader' }, { text: 'Supplier', style: 'tableHeader' }, { text: 'Best Price Received', style: 'tableHeader' }, { text: 'Price Given', style: 'tableHeader' }, { text: 'Status', style: 'tableHeader' }, { text: 'Remarks', style: 'tableHeader' }],
                                ...data.map((item, key) => [
                                    key + 1,
                                    item.query_id,
                                    item.date,
                                    item.make,
                                    item.model,
                                    item.year,
                                    item.body_part,
                                    item.cus_name || 'N/A',
                                    item.phone || 'N/A',
                                    (item.supplier != null && item.supplier != "" && item.supplier != undefined) ? supplierIdToName[item.supplier] ?? "N/A" : 'N/A',
                                    item.best_price_received,
                                    item.price_given,
                                    item.status,
                                    "",
                                ])
                            ],
                            styles: {
                                header: {
                                    fontSize: 18,
                                    bold: true,
                                    margin: [0, 0, 0, 10]
                                },
                                subheader: {
                                    fontSize: 16,
                                    bold: true,
                                    margin: [0, 10, 0, 5]
                                },
                                tableExample: {
                                    margin: [0, 5, 0, 15]
                                },
                                tableHeader: {
                                    bold: true,
                                    fontSize: 13,
                                    color: 'black'
                                }
                            },
                        },
                        layout: {
                            fillColor: function (rowIndex, node, columnIndex) {
                                return (rowIndex % 2 === 0 && rowIndex != 0) ? '#CCCCCC' : rowIndex == 0 ? '#2AFD00' : null;
                            }
                        }
                    },
                ]
            };

            pdfMake.createPdf(docDefinition).download(`queries_${moment(startDate).format('YYYY-MM-DD')}_${moment(endDate).format('YYYY-MM-DD')}${searchStatus ? "_" + searchStatus.replaceAll(" ", "_") : ""}.pdf`);
            //not needed as almost all modern browsers open PDFs directly automatically after downloading
            // pdfMake.createPdf(docDefinition).open();
        }
        else {
            alert("Refine your search conditions to have some results in-order to generate PDF.")
        }
        return;
    };
    const generateExcel = (data) => {
        if (data.length > 0) {
            handleOpenSuccess(`Exporting data to Excel format, from ${moment(startDate).format("DD-MM-YYYY")} to ${moment(endDate).format("DD-MM-YYYY")}${searchStatus != "" ? ", with status " + searchStatus : "."} contains ${data.length} rows.`)
            // Convert data to worksheet format
            const worksheetData = [
                ['SlNo', 'Query ID', 'Date', 'Make', 'Model', 'Year', 'Part', 'Customer Name', 'Phone', 'Supplier', 'Best Price Received', 'Price Given', 'Status', 'Remarks'],
                ...data.map((item, key) => [
                    key + 1,
                    item.query_id,
                    item?.timeline[0] ? (() => { const [key, val] = Object.entries(item.timeline[0])[0]; return val; })() : item.date,
                    item.make,
                    item.model,
                    item.year,
                    item.body_part,
                    item.cus_name || 'N/A',
                    item.phone || 'N/A',
                    item.supplier != "" ? supplierIdToName[item.supplier] ?? "N/A" : 'N/A',
                    item.best_price_received,
                    item.price_given,
                    item.status,
                    ""
                ])
            ];

            // Create a new workbook
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

            // Append worksheet to workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Queries');

            // Format the filename
            const filename = `queries_${moment(startDate).format('YYYY-MM-DD')}_${moment(endDate).format('YYYY-MM-DD')}${searchStatus ? "_" + searchStatus.replaceAll(" ", "_") : ""}.xlsx`;

            // Write workbook and download
            XLSX.writeFile(workbook, filename);
        } else {
            alert("Refine your search conditions to have some results in-order to export to Excel.");
        }
    };

    function phonenumberformat(number) {
        number = number.replace(/[^0-9]/g, '');
        if (number.length === 10) {
            if (number.startsWith('05')) {
                number = number.replace('05', '9715');
            }
            else {
                number = '91' + number;
            }
        }
        number = number + "@c.us";
        return number;
    };

    const resetSearchDates = () => {
        setStartDate("");
        setEndDate("");
    };
    const reload_cost_prices = (head) => {
        axios.post(API_DOMAIN + "/whatsapp/get_costprices", { head }).then((res) => {
            if (res.data.success) {
                setCostPriceSet(res.data.data);
            }
        });
    };
    const reload_customers = (head) => {
        axios.post(API_DOMAIN + "/whatsapp/get_customers", { head }).then((res) => {
            if (res.data.success) {
                set_cus_name_set(res.data.data);
            }
        });
    };
    const reload_suppliers = (head) => {
        axios.post(API_DOMAIN + "/whatsapp/get_suppliersV2", { head }).then((res) => {
            setsupplierSet(res.data.data);
            // console.log("res.data.data = " + JSON.stringify(res.data.data));
            var supSet = supplierSet;
            phoneToNameMap = supSet.reduce((map, supplier) => {
                map[phonenumberformat(supplier.number)] = supplier.name;
                return map;
            }, {});
            supplierIdToName = supSet.reduce((map, supplier) => {
                map[supplier.id] = supplier.name;
                return map;
            }, {});
        });
    };
    const reload_makes = (head) => {
        axios.post(API_DOMAIN + "/whatsapp/get_supplier_tags", { head }).then((res) => {
            if (res.data.success) {
                var makeSetTemp = [];
                // const transformedQuerySet = makeSetTemp.map(item => item.name);
                for (let i = 0; i < res.data.data.length; i++) {
                    makeSetTemp.push({ value: res.data.data[i].id, label: res.data.data[i].name });
                }
                setmakeSet(makeSetTemp);
            }
        });
    };
    const reload_querysets = (head) => {
        var from = moment(startDate).format('YYYY-MM-DD');
        var to = moment(endDate).format('YYYY-MM-DD');
        axios.post(API_DOMAIN + "/whatsapp/get_querysets", {
            head, from, to
        }).then((res) => {
            if (res.data.success) {
                setisLoading(false);
                handling_setquerySet(res.data.data);
            }
        });

        // setTimeout(() => {
        //     reload_querysets(head);
        // }, 5000); //repeat call after 3 minutes (180000 milliseconds)
    };
    const reload_region = (head) => {
        axios.post(API_DOMAIN + "/whatsapp/get_region", { head }).then((res) => {
            setRegionDataSet(res.data.data);
        });

    };
    const reload_brands = async (head, value = "") => {
        try {
            set_model_data(null)
            const res = await axios.post(API_DOMAIN + "/whatsapp/get_makes_v2", { head, name: value });
            if (res.data.success) {
                const makeSetTemp = res.data.data.map(item => ({
                    id: item.id,
                    label: item.name
                }));
                setbrandSet(makeSetTemp);
            }
        } catch (error) {
            console.error("Error reloading brands:", error);
        }
    };
    const load_models = (value) => {
        axios.post(API_DOMAIN + "/whatsapp/get_models_v2", { make: value }).then((res) => {
            if (res.data.success) {
                const ModelTemp = res.data.data.map(item => ({ id: item.id, label: item.model }));
                setModelSet(ModelTemp);
            }
        });

    };

    const computePriceToPercent = (bestPrice, givenPrice, DeliveryCost) => {
        return (((parseFloat(givenPrice) - parseFloat(bestPrice) - parseFloat(DeliveryCost)) / parseFloat(bestPrice)) * 100).toFixed(2);
    };

    const computePercentToPrice = (bestPrice, percentage, DeliveryCost) => {
        // alert("bestPrice=" + bestPrice + "percentage= " + percentage + "DeliveryCost=" + DeliveryCost)
        const price = (((parseFloat(bestPrice) * parseFloat(percentage)) / 100) + parseFloat(bestPrice) + parseFloat(DeliveryCost)).toFixed(2);
        return price;
    };

    const handlePriceValueChanges = (query_id, field, value) => {
        setPriceValues(prevPriceValues => {
            const updatedPriceValues = {
                ...prevPriceValues,
                [query_id]: {
                    ...prevPriceValues[query_id],
                    [field]: value
                }
            };
            return updatedPriceValues;
        });
    };

    //best price received change handler
    const handleBestPriceChange = (query_id, best_price_received) => {
        handlePriceValueChanges(query_id, "best_price_received", best_price_received);

        if (best_price_received) {
            var cost_data = (best_price_received != 0 ? costPriceSet.find(item => best_price_received >= item.MinPrice && best_price_received <= item.MaxPrice) : 0) || { DeliveryCost: 0, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0 }

            handlePriceValueChanges(query_id, "price_percentage", cost_data.GP_Percentage);
            handlePriceValueChanges(query_id, "delivery_cost", cost_data);

            handlePriceValueChanges(query_id, "price_given", 0);

            const price_percentage = cost_data.GP_Percentage;

            if (price_percentage != 0) {
                var price = computePercentToPrice(best_price_received, price_percentage, cost_data.DeliveryCost)
                handlePriceValueChanges(query_id, "price_given", price);
            }
        } else {
            handlePriceValueChanges(query_id, "price_percentage", 0);
            handlePriceValueChanges(query_id, "price_given", 0);
            handlePriceValueChanges(query_id, "delivery_cost", { DeliveryCost: 0, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0 });
        }
    };

    //percentage number change handler
    const handlePercentageChange = (query_id, percent) => {
        const best_price = priceValues[query_id]['best_price_received'] ?? 0;
        if (best_price == 0) {
            alert("Please enter the Best Price received for the item first.");
        }
        else {
            handlePriceValueChanges(query_id, "price_percentage", percent);

            const deliveryCost = priceValues[query_id]['delivery_cost']['DeliveryCost'];

            if (percent != 0) {
                var price = computePercentToPrice(best_price, percent, deliveryCost)
                handlePriceValueChanges(query_id, "price_given", price);
            }
            else {
                handlePriceValueChanges(query_id, "price_given", best_price + deliveryCost);
            }

        }

    };

    //given price change handler
    const handleGivenPriceChange = (query_id, given_price) => {
        const best_price = priceValues[query_id]['best_price_received'] ?? 0;

        if (best_price == 0) {
            alert("Please enter the Best Price received for the item first.");
        }
        else if (given_price == 0) {
            handlePriceValueChanges(query_id, "price_given", given_price);
            handlePriceValueChanges(query_id, "price_percentage", 0);
            handlePriceValueChanges(query_id, "delivery_cost", { DeliveryCost: 0, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0 });
        }
        else {
            handlePriceValueChanges(query_id, "price_given", given_price);
            const deliveryCost = priceValues[query_id]['delivery_cost']['DeliveryCost'];

            const percentage = computePriceToPercent(best_price, given_price, deliveryCost);
            handlePriceValueChanges(query_id, "price_percentage", percentage);
        }
    };

    //given price change handler
    const handleDeliverycostChange = (query_id, delivery_cost) => {
        const best_price = priceValues[query_id]['best_price_received'] ?? 0;

        if (best_price == 0) {
            alert("Please enter the Best Price received for the item first.");
        }
        else {
            if (delivery_cost) {
                handlePriceValueChanges(query_id, "delivery_cost", { DeliveryCost: delivery_cost, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0 });

                const percentage = priceValues[query_id]['price_percentage'];

                const given_price = computePercentToPrice(best_price, percentage, delivery_cost);
                handlePriceValueChanges(query_id, "price_given", given_price);
            }
            else {
                handlePriceValueChanges(query_id, "delivery_cost", { DeliveryCost: delivery_cost, GP_Percentage: 0, MinPrice: 0, MaxPrice: 0 });

                const percentage = priceValues[query_id]['price_percentage'];

                const given_price = computePercentToPrice(best_price, percentage, 0);
                handlePriceValueChanges(query_id, "price_given", given_price);
            }
        }
    };

    //update to DB upon any price change
    const handlePriceUpdate = (query_id) => {
        const status = priceValues[query_id]['status'];

        const best_price_received = priceValues[query_id]['best_price_received'];
        const price_given = priceValues[query_id]['price_given'];
        const deliveryCost = priceValues[query_id]['delivery_cost']['DeliveryCost'];
        if (best_price_received != 0 && price_given != 0) {
            if (status == "Waiting for Price") {
                handleQueryUpdate("", false, "Waiting for confirmation", true, price_given, true, best_price_received, true, query_id, deliveryCost, true, "", false, false);
                handlePriceValueChanges(query_id, "status", "Waiting for confirmation")
                handleOpenSuccess("Query status also has been updated for your convenience!")
            } else {
                handleQueryUpdate("", false, "", false, price_given, true, best_price_received, true, query_id, deliveryCost, true, "", false, false);
            }
        }
    }

    const handleStatusValueChange = (value, query_id) => {
        handlePriceValueChanges(query_id, 'statusValue', value);
    }

    const handleStatusValueUpdate = (value, query_id) => {
        console.log("statusValue =", value);
        if (value) {
            var mdetails = queryDataMap[query_id].moredetails;
            mdetails.statusValue = value;
            handleQueryUpdate("", false, "", false, "", false, "", false, query_id, "", false, mdetails, true, false);
        }
    }

    const handling_updated_query = (newQuery) => {
        setquerySet(querySet.map((query) => {
            if (newQuery.query_id === query.query_id) {
                return newQuery;
            }
            return query;
        }));
    }

    const handling_added_query = (newQuery) => {
        setquerySet([...querySet, newQuery]);
    }

    socket.on('QueryDataUpdated', (values) => {
        var data = values.data;
        handleOpenSuccess(values.message, '(ID : ' + data[0].query_id + ")");
        handling_updated_query(data);
    })
    socket.on('newQueryLoaded', (values) => {
        var data = values.data;
        handleOpenSuccess(values.message);
        handling_added_query(data);
    })


    useEffect(() => {

        const head = {
            headers: {
                accessToken: localStorage.getItem("accessToken")
            },
            api_secret: "uzdpart"
        };
        reload_querysets(head);
        //this is the tags
        reload_makes(head);
        //brand names, models will load when a brand is selected
        reload_brands(head);
        //now it loads the supplierv2 data
        reload_suppliers(head);
        reload_customers(head);
        reload_region(head);
        reload_cost_prices(head);
        const waStatusCheck = async () => {
            axios.post(API_DOMAIN + "/whatsapp/status", { api_secret: "uzdpart" }, head).then((res) => {
                if (res.data.success) {
                    setWhatsappStatus(res.data.data.status);
                    switch (res.data.data.status.status) {
                        case eventStatusMap.logged_in:
                            document.getElementById('wa_status_img_small').src = green_tick
                            break;
                        default:
                            document.getElementById('wa_status_img_small').src = yellow_error
                            setTimeout(() => { waStatusCheck(); }, 3000);
                            break;
                    }
                } else {
                    document.getElementById('wa_status_img_small').src = yellow_error
                }
            }).catch((err) => { handleOpenError(err.message) });
        }
        axios.post(API_DOMAIN + "/whatsapp/get_query_succ_status", { api_secret: "uzdpart" }, head).then((res) => {
            if (res.data.success) {
                setQueryStatusSet(res.data.data)
            }
        }).catch((err) => { handleOpenError(err.message) });
        if (Notification.permission === 'default') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                } else {
                    alert("Please allow to show notifications so that You'll know when the query got resolved.")
                    console.log('Notification permission denied.');
                }
            });
        }
        waStatusCheck();

        const handleWindowFocus = () => {
            setIsTabFocused(true);
        };

        const handleWindowBlur = () => {
            setIsTabFocused(false);
            queryDateRef?.current?.setOpen(false);
        };

        window.addEventListener('focus', handleWindowFocus);
        window.addEventListener('blur', handleWindowBlur);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('focus', handleWindowFocus);
            window.removeEventListener('blur', handleWindowBlur);
            socket.off('QueryDataUpdated');
            socket.off('newQueryLoaded');
        };

    }, []);
    useEffect(() => {
        isTabFocusedRef.current = isTabFocused;
    }, [isTabFocused]);
    useEffect(() => {
        handleMakeChange(make_data);
    }, [make_data]);

    const [priceValues, setPriceValues] = useState({});
    const [parallel, setParallel] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [queryDataMap, setQueryDataMap] = useState({});


    const reloadQuerySet = () => {
        document.getElementById('pdf_button').innerHTML = "Loading..."
        document.getElementById('excel_button').innerHTML = "Loading..."
        document.getElementById('pdf_button').disabled = true;
        document.getElementById('excel_button').disabled = true;
        setisLoading(true);
        axios.post(API_DOMAIN + "/whatsapp/get_querysets", {
            search: searchTerm,
            from: moment(startDate).isValid() ? moment(startDate).format('YYYY-MM-DD') : "", to: moment(endDate).isValid() ? moment(endDate).format('YYYY-MM-DD') : "", status: searchStatus, api_secret: "uzdpart"
        }).then((res) => {
            document.getElementById('pdf_button').innerHTML = "Download PDF";
            document.getElementById('excel_button').innerHTML = "Download XLS";
            document.getElementById('pdf_button').disabled = false;
            document.getElementById('excel_button').disabled = false;
            if (res.data.success) {
                setPageNumber(0);
                setisLoading(false);
                handling_setquerySet(res.data.data);
                if (searchTerm || moment(startDate).isValid() || moment(endDate).isValid() || searchStatus) {

                    handleOpenSuccess("Fetched " + res.data.data.length + " search results")
                }
            }
        })
            .catch((error) => {
                // Handle error if needed
            });
    };

    // Pagination Information
    const [pageNumber, setPageNumber] = useState(0);
    const usersPerPage = 50;
    const visitedPages = pageNumber * usersPerPage;
    const pageCount = Math.ceil(querySet.length / usersPerPage);
    const changePage = ({ selected }) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setPageNumber(selected);
    };

    // Displaying the users of this particular page in the pagination
    const displayUsers = querySet.slice(visitedPages, visitedPages + usersPerPage).map((value, index) => {
        console.log("value = " + value.query_id);
        return (
            <div className='item' key={value.query_id}>
                <div className='name'>
                    <span style={{ display: "flex", fontSize: "large", color: "black" }}>Query ID : <div style={{ color: "white", fontWeight: "700" }}>{value.query_id}</div></span>

                    <span style={{ color: "black", fontWeight: "bold", fontSize: "medium", width: "55vw", textAlign: "center" }}>{value.body_part}</span>

                    <span style={{ display: "flex", fontSize: "medium", color: "black" }}>Date : <div style={{ color: "white", fontWeight: "700" }}>{value.date}</div></span>
                </div>
                <div className='body'>

                    <div style={{ height: "225px" }}>
                        <Carousel
                            infiniteLoop={true}
                            showThumbs={false}
                            showStatus={false}
                            autoPlay={true}
                            interval={3000}
                            width={"220px"}
                            emulateTouch={true}>
                            {value.image.map((item, index) => (
                                <div key={index} style={{ width: "220px", height: "220px" }}>
                                    <img
                                        style={{ width: "220px", border: "2px solid", borderRadius: "10px", height: "220px" }}
                                        src={API_DOMAIN + "/" + item}
                                        alt={(`${API_DOMAIN}/images/no_img.jpg`)}
                                        loading='lazy'
                                    />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                    <div>
                        <div>
                            <div style={{ display: "flex", padding: "0px 0px 0px 10px" }}>
                                <Tooltip title="Now you can send single query to multiple supplier groups all at once while preparing the query. The BOT Status below shows the report of the combined WA send status.">
                                    <table>
                                        <tr>
                                            <td>
                                                Supplier Groups :&nbsp;
                                            </td>
                                            <td style={{ fontWeight: "bold", width: "300px" }}>
                                                {value?.sup_set?.map((element) => element.label).join(', ')}
                                            </td>
                                        </tr>
                                    </table>
                                </Tooltip>
                                <div style={{ marginLeft: "auto" }}>
                                    <table>
                                        <tr>
                                            <td>
                                                Current Status :&nbsp;
                                            </td>
                                            <td style={{ width: "250px" }}>
                                                <select
                                                    id="status_value"
                                                    value={priceValues[value.query_id]['status']}
                                                    class="custom-select label-look"
                                                    onChange={(e) => handleQueryUpdate('', false, e.target.value, true, '', false, '', false, value.query_id, '', '', value.moredetails, true, false)}
                                                    style={{ width: "100%" }}
                                                >
                                                    <option value=""></option>
                                                    {queryStatusSet.map((item) => (
                                                        <option key={item.id} value={item.name}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="text-content">
                            <table style={{ width: "100%", border: "1px solid", borderRadius: "5px", paddingLeft: "35px", paddingTop: "10px", paddingBottom: "10px" }}>
                                <tbody>
                                    <tr>
                                        <td>Make</td>
                                        <td>:</td>
                                        <td className='queryset_data'>{value.make ? value.make : "N/A"}</td>
                                        <td> </td>
                                        <td>Customer Name</td>
                                        <td>:</td>
                                        <td className='queryset_data'>{value.cus_name ? value.cus_name : "N/A"}</td>

                                    </tr>
                                    <tr>
                                        <td>Model</td>
                                        <td>:</td>
                                        <td className='queryset_data'>{value.model ? value.model : "N/A"}{value.model_details ? " - " + value.model_details : ""}</td>
                                        <td> </td>
                                        <td>Customer Phone</td>
                                        <td>:</td>
                                        <td className='queryset_data'>{value.phone ? value.phone : "N/A"}</td>
                                    </tr>
                                    <tr>
                                        <td>Chassis No</td>
                                        <td>:</td>
                                        <td className='queryset_data'>{value.chassis_no ? value.chassis_no : "N/A"}</td>
                                        <td> </td>
                                        <td>Best Price Received</td>
                                        <td>:</td>
                                        <td className='queryset_data'>
                                            <input
                                                id='best_price_received'
                                                style={{ width: "70%" }}
                                                value={priceValues[value.query_id]['best_price_received']}
                                                onFocus={handleFocus}
                                                onChange={(e) => handleBestPriceChange(value.query_id, e.target.value)}
                                                onBlur={(e) => handlePriceUpdate(value.query_id)}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Cylinder</td>
                                        <td>:</td>
                                        <td className='queryset_data'>{value.cylinder ? value.cylinder : "N/A"}</td>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>

                                    </tr>
                                    <tr>
                                        <td>Year</td>
                                        <td>:</td>
                                        <td className='queryset_data'>{value.year ? value.year : "N/A"}</td>
                                        <td> </td>

                                        <td>Price Given</td>
                                        <td>:</td>
                                        <td className='queryset_data'>
                                            <div key={value.query_id} style={{ display: "flex", alignItems: "center" }}>
                                                {priceValues[value.query_id]['price_percentage'] == 0 ?
                                                    <div>
                                                        <ImportExport />
                                                    </div>
                                                    :
                                                    priceValues[value.query_id]['price_percentage'] > 0 ?
                                                        <div style={{ color: "green" }}>
                                                            <ArrowUpwardIcon />
                                                        </div>
                                                        :
                                                        <div style={{ color: "red" }}>
                                                            <ArrowDownwardIcon />
                                                        </div>}
                                                <input
                                                    style={{ width: "21%" }}
                                                    id={'price_percentage' + value.query_id}
                                                    value={priceValues[value.query_id]['price_percentage']}
                                                    onChange={(e) => handlePercentageChange(value.query_id, e.target.value)}
                                                    onBlur={(e) => handlePriceUpdate(value.query_id)}
                                                />
                                                <div style={{ color: "grey", transform: "translateX(-4px)", marginLeft: "-14px" }}>%</div>
                                                <div>&nbsp;+&nbsp;</div>
                                                <input
                                                    style={{ width: "29%" }}
                                                    id={'delivery_cost' + value.query_id}
                                                    value={priceValues[value.query_id]['delivery_cost']['DeliveryCost']}
                                                    onChange={(e) => handleDeliverycostChange(value.query_id, e.target.value)}
                                                    onFocus={handleFocus}
                                                    onBlur={(e) => handlePriceUpdate(value.query_id)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Region</td>
                                        <td>:</td>
                                        <td className='queryset_data'>{value.region ? value.region : "N/A"}</td>
                                        <td> </td>
                                        <td> </td>
                                        <td> </td>
                                        <td className='queryset_data'>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div>&nbsp;=&nbsp;</div>
                                                <input
                                                    style={{ width: "63%", border: "2px solid", borderRadius: "5px", height: "20px" }}
                                                    id={'price_given' + value.query_id}
                                                    value={priceValues[value.query_id]['price_given']}
                                                    onChange={(e) => handleGivenPriceChange(value.query_id, e.target.value)}
                                                    onFocus={handleFocus}
                                                    onBlur={(e) => handlePriceUpdate(value.query_id)}
                                                />
                                            </div>
                                        </td>
                                        {/* <td>Staff Ref</td>
                                    <td>:</td>
                                    <td className='queryset_data'>
                                        <select
                                            onChange={(e) => handleQueryUpdate('', false, e.target.value, true, '', false, '', false, value.query_id, false)}
                                            id="status"
                                            name="status"
                                            defaultValue={value.status}
                                            style={{ width: "73%" }}
                                        >
                                            <option value="value0"></option>
                                            <option value="Waiting for Price">Waiting for Price</option>
                                            <option value="Waiting for confirmation">Waiting for confirmation</option>
                                            <option value="Confirmed">Confirmed</option>
                                            <option value="Out for delivery">Out for delivery</option>
                                            <option value="Delivered">Delivered</option>
                                            <option value="Cancelled">Cancelled</option>
                                            <option value="Unavailable">Unavailable</option>
                                        </select>
                                    </td> */}
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>&nbsp;&nbsp;&nbsp;</div>
                    <div style={{ border: "1px black solid", borderRadius: "5px", width: "250px", height: "225px", overflowY: "auto" }}>
                        {/* <div style={{ fontSize: "10px", fontWeight: "710" }}>ACTIVITY TIMELINE</div> */}
                        <Timeline position="right">
                            {
                                value.timeline ? value.timeline.map((timvalue, index) => {
                                    const [key, val] = Object.entries(timvalue)[0]; // Extract the first key-value pair from the object
                                    return (
                                        <TimelineItem key={index}>
                                            <TimelineOppositeContent className="roboto-font" style={{ fontSize: "10px", paddingLeft: "0px" }}>
                                                {key}
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent className="roboto-font" style={{ fontSize: "10px" }}>
                                                {val}
                                            </TimelineContent>
                                        </TimelineItem>
                                    );
                                })
                                    :
                                    <div>
                                        NO HISTORY DATA!
                                    </div>
                            }
                        </Timeline>
                    </div>
                    <div>&nbsp;&nbsp;&nbsp;</div>
                </div >
                <div className='footer gradient-custom'>
                    <div>Supplier :
                        <select
                            class="custom-select label-look"
                            style={{ width: "350px" }}
                            value={value.supplier}
                            onChange={(e) => handleQueryUpdate(e.target.value, true, '', false, '', false, '', false, value.query_id, '', '', '', false, false)}
                        >
                            <option value=""></option>
                            {supplierSet.map((item) => (
                                <option key={item.id} value={item.id ? item.id : ""}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <Tooltip title="Result of processed query, Green number refers to the successful and Red refers to the failed query sendings.">
                        <div>
                            {value.moredetails.hasOwnProperty('success') ? (
                                <div id="bot_Stat" style={{ fontSize: "small", display: "flex", border: "1px solid black", borderRadius: "5px", paddingLeft: "10px", paddingRight: "10px", paddingTop: "3px", paddingBottom: "3px" }}>
                                    <div style={{ color: "black" }}>BOT STATUS :&nbsp;</div>
                                    <div style={{ color: "green" }}>{value.moredetails.success.length}</div>
                                    <div style={{ color: "black" }}>&nbsp;+&nbsp;</div>
                                    <div id='failed_wa' style={{ color: "red" }}>{value.moredetails.failed.length}</div>
                                    <div style={{ color: "black" }}>&nbsp;=&nbsp;</div>
                                    <div style={{ color: "black" }}>{value.moredetails.success.length + value.moredetails.failed.length}</div>
                                </div>
                            ) : ""}
                        </div>
                    </Tooltip>
                    <div style={{ width: "135px", border: "1px solid black", borderRadius: "5px", paddingLeft: "10px", paddingRight: "10px", paddingTop: "3px", paddingBottom: "3px", display: "flex", marginRight: "100px", marginLeft: "" }}>
                        <Tooltip title="Total time taken till its last status change from its creation.">
                            <div style={{ color: "black" }}>
                                Query Duration: {value?.timeline ? (() => {
                                    const [key1, val1] = Object.entries(value.timeline[0])[0];

                                    // Filter entries to find all occurrences of "price_changed"
                                    const priceChangedEntries = value.timeline.filter(element => {
                                        const [key, val] = Object.entries(element)[0];
                                        return key === "price_changed";
                                    });

                                    // Get the last occurrence of "price_changed" or the last entry in the timeline
                                    const lastEntry = priceChangedEntries.length > 0
                                        ? priceChangedEntries[priceChangedEntries.length - 1]
                                        : value.timeline[value.timeline.length - 1];

                                    const [key2, val2] = Object.entries(lastEntry)[0];

                                    const secondss = moment(val2).diff(moment(val1), 'seconds');

                                    const minutes = Math.floor(secondss / 60);
                                    const remSeconds = secondss % 60;
                                    return `${minutes}:${remSeconds < 10 ? '0' : ''}${remSeconds}`;
                                })() : "NO DATA"}
                            </div>

                        </Tooltip>
                    </div>
                    {priceValues[value.query_id]['status'] == 'Postponed' ? (<div id={"postponedto_" + value.query_id} style={{ width: "240px", border: "1px solid black", borderRadius: "5px", paddingLeft: "10px", paddingRight: "10px", paddingTop: "2px", paddingBottom: "2px", display: "flex", marginLeft: "-305px", marginRight: "-210px" }}>
                        <div style={{ color: "black", display: "flex" }}>
                            Postponed to :&nbsp;
                            <div style={{ width: "80px" }}>
                                <DatePicker
                                    id={'statusValue' + value.query_id}
                                    selected={new Date(moment(priceValues[value.query_id]['statusValue']).format('YYYY-MM-DD'))}
                                    onFocus={handleFocus}
                                    onChange={(date) => handleStatusValueChange(date, value.query_id)}
                                    onBlur={(e) => handleStatusValueUpdate(e.target.value, value.query_id)}
                                    dateFormat="yyyy-MM-dd"
                                    minDate={new Date()}
                                    maxDate={new Date(moment().add(1, 'years').format('YYYY-MM-DD'))}
                                    className='react-datepicker-query'
                                />
                            </div>
                        </div>
                    </div>) : priceValues[value.query_id]['status'] == 'Other' || priceValues[value.query_id]['status'] == 'Customer Declined' || priceValues[value.query_id]['status'] == 'Cancelled' ? (<div id={"postponedto_" + value.query_id} style={{ width: "265px", border: "1px solid black", borderRadius: "5px", paddingLeft: "10px", paddingRight: "10px", paddingTop: "2px", paddingBottom: "2px", display: "flex", marginLeft: "-305px", marginRight: "-210px" }}>
                        <div style={{ color: "black", display: "flex" }}>
                            Reason :&nbsp;
                            <div style={{ width: "80px" }}>
                                <input onFocus={handleFocus} id={'statusValue' + value.query_id} value={priceValues[value.query_id]['statusValue']} style={{ width: "190px" }} onChange={(e) => { handleStatusValueChange(e.target.value, value.query_id) }} onBlur={(e) => handleStatusValueUpdate(e.target.value, value.query_id)}></input>
                            </div>
                        </div>
                    </div>) : ""}
                    <div>
                        <Tooltip title="Go to editing mode with this Query data and send a new one.">
                            <EditIcon style={{ color: 'teal', cursor: 'pointer' }} onClick={() => { handleQueryEdit(value.query_id, value.phone) }} />
                        </Tooltip>
                        {/* <Tooltip title="Unsent the message and delete it.">
                            <DeleteOutlineRounded style={{ color: 'teal', cursor: 'pointer' }} onClick={() => { handleUnsend(value.query_id) }} />
                        </Tooltip> */}
                        <Tooltip title="Delete this Query.">
                            <DeleteOutlineRounded
                                style={{ color: 'teal', cursor: 'pointer' }}
                                onClick={() => window.confirm("Do you want to delete this Queryset?") ? handleQueryUpdate('', '', '', '', '', '', '', '', value.query_id, '', '', '', false, true) : ""}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div >
        );
    });

    return (
        <>
            <div>
                <div style={{ display: "flex", width: "95vw", border: "1px black solid", borderRadius: "10px", height: "63px", backgroundColor: "rgb(61, 180, 248)", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", marginLeft: "1%" }}>
                        <input value={searchTerm} id="search_field" style={{ height: "3em", marginTop: "8px", borderRadius: "5px", width: "180px" }} onChange={(e) => setSearchTerm(e.target.value)} placeholder='Search'></input>
                        <span style={{ cursor: "pointer", fontSize: "17px", color: "gray", marginTop: "20px", transform: "translateX(-160%)" }} class="input-reset" onClick={() => resetInput('search_field')}>&#10005;</span>
                        <DatePicker
                            selected={startDate}
                            onChange={onSearchDateChange}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            placeholderText="Date Range From - To"
                            className='react-datepicker'
                            dateFormat="dd/MM/yyyy"
                            selectsRange
                        />
                        <span style={{ cursor: "pointer", fontSize: "17px", color: "gray", marginTop: "20px", transform: "translateX(-130%)", zIndex: "5" }} class="input-reset" onClick={() => resetSearchDates()}>&#10005;</span>
                        <select
                            onChange={(e) => setSearchStatus(e.target.value)}
                            id="search_status"
                            name="search_status"
                            defaultValue=""
                            style={{ width: "180px", marginTop: "7px", height: "46px", borderRadius: "5px" }}
                        >
                            <option value=""></option>
                            <option value="Waiting for Price">Waiting for Price</option>
                            <option value="Waiting for confirmation">Waiting for confirmation</option>
                            <option value="Confirmed">Confirmed</option>
                            <option value="Out for delivery">Out for delivery</option>
                            <option value="Delivered">Delivered</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Unavailable">Unavailable</option>
                        </select>
                        <button className='button_search' onClick={reloadQuerySet}>Search</button>
                        <button className='button_PDF' id="pdf_button" onClick={handlePdfClick}>Download PDF</button>
                        <button className='button_XLS' id="excel_button" onClick={handleExcelClick}>Download XLS</button>
                    </div>
                    <div style={{ display: "flex", marginRight: "1%" }}>
                        <button className='buttonito' onClick={handleOpen}>Create New Query</button>
                        <Box sx={{ width: "90px", border: "1px solid", borderRadius: "5px", marginTop: "9px", height: "40px", backgroundColor: "#00A07D", alignContent: "center", justifyContent: "center", color: "white", paddingLeft: "10px" }}>
                            <table><tr>
                                <td>
                                    <img src={whatsapp_logo} style={{ width: "30px" }} alt="wa_stat"></img>
                                </td>
                                <td>
                                    :
                                </td>
                                <td>
                                    <Tooltip title="Click here to go to WA-Bot management page.">
                                        <img id="wa_status_img_small" className='wa_stat_img' src={yellow_error} alt='green_tick' onClick={handleWAstatus}></img>
                                    </Tooltip>
                                </td>
                            </tr>
                            </table>
                        </Box>
                    </div>
                </div>
                <div style={{ justifyContent: "space-between", display: "flex" }}>
                    <div>Total {querySet.length} Rows Found</div>
                    <div>Average Query Closing Time : </div>
                    <div>50 Rows shown per-page</div>
                </div>
            </div>
            <div className="container">
                <div className="container">

                    {displayUsers}

                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationButtons"}
                        previousLinkClassName={"previousButton"}
                        nextLinkClassName={"nextButton"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                        forcePage={pageNumber}
                    />

                </div>

                <LoadingComponent isLoading={isLoading} />

                {/* The Add new query modal */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    BackdropProps={{ onClick: (event) => event.stopPropagation() }}
                    disableEscapeKeyDown>

                    <Box sx={style}>
                        <div style={{ textAlign: "center", fontSize: "22px", paddingTop: "0px", marginTop: "0px" }}>Create New Query
                        </div>
                        <table style={{ border: "1px solid", borderRadius: "6px", padding: "10px" }}>
                            <tr>
                                {/* make data setting */}
                                <td colSpan={8}  >
                                    <ReactTags
                                        selected={make_data}
                                        suggestions={makeSet}
                                        placeholderText="Add Supplier Groups"
                                        onAdd={onAdd}
                                        onDelete={onDelete}
                                        noOptionsText="No matches found"
                                    />
                                </td>
                            </tr>
                            <tr>
                                {/* brand data setting */}
                                <td colSpan={4} style={{ paddingTop: "10px" }}>
                                    <Autocomplete
                                        value={brand_data}
                                        onChange={(event, value) => { handleBrandChange(value) }}
                                        disablePortal
                                        options={brandSet}
                                        freeSolo={true}
                                        className="custom-autocomplete"
                                        isOptionEqualToValue={(option, value) => option.label === value}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params} variant="outlined" label="Select Make" InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <>
                                                            {params.InputProps.endAdornment}
                                                            <CustomSpan
                                                                className="input-reset"
                                                                onClick={(e) => handleOpenAddModal("Add New Make")}
                                                            >
                                                                &#65291;
                                                            </CustomSpan>
                                                        </>
                                                    ),
                                                }} required={true} />}
                                    ></Autocomplete>
                                </td>
                                <td colSpan={4} style={{ paddingTop: "10px" }}>
                                    {/* model data setting */}
                                    <Autocomplete
                                        value={model_data}
                                        onChange={(event, value) => { handle_model_change(value) }}
                                        disablePortal
                                        freeSolo={true}
                                        key={key_to_reset}
                                        options={modelSet}
                                        className="custom-autocomplete"
                                        isOptionEqualToValue={(option, value) => option.label === value}
                                        renderInput={(params) => <TextField
                                            {...params} variant="outlined" label="Select Model" InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {params.InputProps.endAdornment}
                                                        <CustomSpan
                                                            className="input-reset"
                                                            onClick={(e) => handleOpenAddModal("Add New Model")}
                                                        >
                                                            &#65291;
                                                        </CustomSpan>
                                                    </>
                                                ),
                                            }} required={true} />}
                                    ></Autocomplete>
                                </td>
                            </tr>
                            <tr>
                                <td className='query_form'>Model Details</td>
                                <td>:</td>
                                <td className="formfield_td" ><input value={model_details_data} id="model_value" spellCheck="true"
                                    onChange={(e) => { set_model_details_data(e.target.value) }} className='query_fields'></input>
                                </td>
                                <td style={{ width: "60px" }}><button style={{ width: "35px", height: "30px", cursor: "pointer" }} onClick={() => pasteClipboardContent('model_value')}>📋</button>
                                </td>
                                <td className='query_form'>No of Cylinders</td>
                                <td>:</td>
                                <td className="formfield_td"><input value={cylinder_data} onChange={(e) => { set_cylinder_data(e.target.value) }} type='number' style={{ width: "175px", height: "25px", marginTop: "10px", borderRadius: "5px", border: "1px solid grey" }}></input></td>
                            </tr>
                            <tr>
                                <td className='query_form' rowSpan={2}>Part<span className="required">*</span></td>
                                <td rowSpan={2}>:</td>
                                <td className="formfield_td" rowSpan={2}>
                                    <textarea rows="3" cols="50" id='part_value' value={bodypart_data} onChange={(e) => { set_bodypart_data(e.target.value) }} className='query_fields' style={{ width: "142px", height: "80px", resize: "none" }} ></textarea>
                                </td>
                                <td rowSpan={2}>
                                    <button style={{ width: "35px", height: "30px", cursor: "pointer" }} onClick={(e) => pasteClipboardContent('part_value')}>📋</button>
                                </td>
                                <td className='query_form'>Region</td>
                                <td>:</td>
                                <td className="formfield_td" >
                                    <select
                                        style={{
                                            width: "180px", height: "30px", borderRadius: "5px",
                                            padding: "6px 10px 5px 20px"
                                        }}
                                        value={region_data}
                                        onChange={(event) => { handleRegionChange(event.target.value) }}
                                    >
                                        <option value=""></option>
                                        {regionDataSet.map((item) => (
                                            <option key={item.id} value={item.region}>
                                                {item.region}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <button style={{ width: "35px", height: "30px", cursor: "pointer" }} onClick={(e) => handleOpenAddModal("Add New Region")}>➕</button></td>
                            </tr>
                            <tr>

                                <td style={{ width: "200px" }}>Customer Name</td>
                                <td style={{ width: "20px" }}>:</td>
                                <td style={{ width: "200px" }}>
                                    <select
                                        style={{
                                            width: "180px", height: "30px", borderRadius: "5px",
                                            padding: "6px 10px 5px 20px"
                                        }}
                                        value={cus_name}
                                        onChange={(event) => { handleCusNameChange(event.target.value) }}
                                    >
                                        <option value=""></option>
                                        {cus_name_set.map((item) => (
                                            <option key={item.customer_id} value={item.customer_id}>
                                                {item.customer_name}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className='query_form'>Year<span className="required">*</span></td>
                                <td>:</td>
                                <td className="formfield_td">
                                    <DatePicker
                                        selected={year_data}
                                        showYearPicker
                                        ref={queryDateRef}
                                        showIcon={true}
                                        maxDate={new Date()}
                                        className='react-datepicker-query'
                                        dateFormat="yyyy"
                                        onChange={(date) => {
                                            set_year_data(date)
                                        }}
                                        sx={{ width: "200px" }}
                                    />
                                </td>
                                <td></td>

                                <td style={{ width: "200px" }}>Customer Phone</td>
                                <td>:</td>
                                <td className="formfield_td" ><input id='phone_value' value={phone_data} onChange={(e) => { handleCusMobChange(e.target.value); set_phone_data(e.target.value); }} className='query_fields'></input>
                                </td>
                                <td>
                                    <button style={{ width: "35px", height: "30px", cursor: "pointer" }} onClick={(e) => pasteClipboardContent('phone_value')}>📋</button></td>
                            </tr>
                            <tr>
                                <td className='query_form'>Chassis No</td>
                                <td>:</td>
                                <td className="formfield_td" ><input id='chassis_value' value={chassisno_data} onChange={(e) => { set_chassisno_data(e.target.value) }} className='query_fields'></input>
                                </td>
                                <td>
                                    <button style={{ width: "35px", height: "30px", cursor: "pointer" }} onClick={(e) => pasteClipboardContent('chassis_value')}>📋</button></td>
                                <td className='query_form'>Image<span className="required">*</span></td>
                                <td>:</td>
                                <td className="formfield_td">
                                    <Tooltip title="Now you can add multiple photos!">
                                        <input id="multi_images" ref={file_DataRef} multiple style={{ width: "180px" }} type="file" accept="image/*" onChange={(e) => handleFileChange(e)} />
                                    </Tooltip>
                                </td>
                                <td>
                                    <Tooltip title="To send Queries One-by-One or all at once, Tick to send all at once. Sending all at once may cause server loads.">
                                        <input
                                            style={{ display: 'none', marginLeft: "22px" }}
                                            id="parallel_send"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={(e) => handleParallelChange(e)}
                                        />
                                    </Tooltip>
                                </td>
                            </tr>
                        </table>
                        <div id='sup_count' style={{ textAlign: "center" }}></div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <button id='modal_close_button' className='buttonito' style={{ width: "170px", backgroundColor: "red" }} onClick={(e) => handleClose()}>close</button>
                            <button id='modal_send_button' className='buttonito' style={{ width: "170px", backgroundColor: "green" }} onClick={(e) => handleSubmit()}>Send it!</button>
                        </div>
                    </Box>
                </Modal>



                <Modal
                    open={open_stat}
                    onClose={handleCloseStat}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    BackdropProps={{ onClick: (event) => event.stopPropagation() }}
                    disableEscapeKeyDown >
                    <Box sx={style_wa}>
                        <div className='headerito' style={{ padding: "0px 0px 15px 0px " }}>Processed Query Status</div>
                        <table style={{ border: '1px solid black', borderRadius: '5px', width: '100%' }}>
                            <thead>
                                <tr>
                                    <th style={{ border: '1px solid black' }}>Send Success ({waStatus.success.length})</th>
                                    <th style={{ border: '1px solid black' }}>Send Failed ({waStatus.failed.length})</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: '1px solid black', height: 'auto', verticalAlign: 'top' }}>
                                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    {waStatus.success.map((item, index) => (
                                                        <tr key={index}>
                                                            <td style={{ border: '1px solid black' }}>{index + 1}</td>
                                                            <td style={{ border: '1px solid black' }}>{item.number}</td>
                                                            <td style={{ border: '1px solid black' }}>{item.name}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                    <td style={{ border: '1px solid black', height: 'auto', verticalAlign: 'top' }}>
                                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                            <table style={{ width: '100%' }}>
                                                <tbody>
                                                    {waStatus.failed.map((item, index) => (
                                                        <tr key={index}>
                                                            <td style={{ border: '1px solid black' }}>{index + 1}</td>
                                                            <td style={{ border: '1px solid black' }}>{item.number}</td>
                                                            <td style={{ border: '1px solid black' }}>{item.name}</td>
                                                            <td style={{ border: '1px solid black' }}>{item.reason}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                            <button id='wastat_modal_close_button' className='buttonito' style={{ width: "170px", backgroundColor: "red" }} onClick={(e) => handleCloseStat}>close</button>
                        </div>
                    </Box>
                </Modal>

                {/*Modal to showup to add region,make,model and stuff */}
                <Modal
                    open={open_add_modal}
                    onClose={handleCloseAddModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style_region}>
                        <div className='headerito' style={{ padding: "0px 0px 15px 0px " }}>{modalHeading}</div>
                        <div>
                            <input id='region_value' value={modal_data} onChange={(e) => set_modal_data(e.target.value)} style={{ width: "300px" }} className='query_fields'></input>
                        </div>
                        <div>&nbsp;</div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <button id='wastat_modal_close_button' className='buttonito' style={{ width: "170px", backgroundColor: "red" }} onClick={(e) => handleCloseAddModal()}>close</button>
                            <button id='wastat_modal_close_button' className='buttonito' style={{ width: "170px", backgroundColor: "green" }} onClick={(e) => handleModalAdd(e, modalHeading)}>Save</button>
                        </div>
                    </Box>
                </Modal>


                {/* General purpose success alert */}
                <Snackbar open={openSuccessAlert} autoHideDuration={6000} onClose={handleCloseSuccess}>
                    <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>

                {/* Error message alert */}
                <Snackbar open={openErrorAlert} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="warning" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>

            </div >
        </>
    );

}

export default Queryset;