import React, { useEffect, useState } from 'react';
import whatsapp_placeholder from '../assets/images/whatsapp_placeholder.png';
import useSnackbar from "../components/Snackbar/UseSnackbar";
import axios from 'axios';
import { API_DOMAIN } from '../components/constants';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Avatar from '@mui/material/Avatar';
import { Stack } from '@mui/material';

function WhatsappMessages() {


    const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();


    const [chatlist, setChatlist] = useState([]);


    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        if (!name) {
            return "";
        }
        if (name.split(' ').length === 2) {
            // If name has two parts (first name and last name)
            return {
                sx: {
                    bgcolor: stringToColor(name),
                },
                children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
            };
        } else {
            // If name has only one part
            return {
                sx: {
                    bgcolor: stringToColor(name),
                },
                children: `${name.split(' ')[0][0].toUpperCase()}`, // Capitalize first letter
            };
        }
    }


    const getAllChats = () => {
        axios.get(API_DOMAIN + "/whatsapp/get_all_chats", {
            headers: {
                accessToken: localStorage.getItem("accessToken"),
            }
        }).then((res) => {
            if (res.data.success) {
                setChatlist(res.data.data);
                SuccessSnackbar("Chatlist loaded successfully");
            }
            else {

            }
        });
    }

    useEffect(() => {
        getAllChats();
    }, []);

    return (
        <>
            <div style={{
                height: "80vh",
                width: "90vw",
                border: "black solid 1px",
                borderRadius: "5px",
                backgroundColor: "#21c063",
                display: "grid",
                gridTemplateRows: "auto 1fr",
                justifyContent: "center",
            }}>
                <div style={{ display: "flex", paddingTop: "2.5vh" }}>
                    <div style={{
                        height: "75vh",
                        width: "19vw",
                        border: "black solid 1px",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        paddingTop: "3vh"
                    }}>

                        {/* This div contains the chats list on the left pane */}
                        <div style={{ display: "flex", justifyContent: "center" }}>

                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid black', borderRadius: '5px', width: '90%', justifyContent: "center" }}>
                                <input
                                    type='text'
                                    placeholder='Search'
                                    style={{
                                        width: '100%',
                                        height: '30px',
                                        border: 'none',
                                        paddingLeft: '5px',
                                        outline: 'none'
                                    }}
                                />
                                <span style={{ padding: '0 10px', cursor: 'pointer' }}>
                                    <SearchOutlinedIcon />
                                </span>
                            </div>
                        </div>
                        <div style={{ justifySelf: "right" }}>
                            <div style={{ fontSize: "10px" }}>Total:{chatlist.length || 0}</div>
                        </div>


                        {/* Chatlist starts here */}
                        <div style={{ overflowY: "auto", overflowX: "clip", border: "1px solid black", height: "63vh", borderRadius: "5px", marginTop: "10px", width: "10" }}>
                            {chatlist.map((chat, index) =>
                            (
                                <div style={{ height: "70px", border: "1px solid black", borderRadius: "5px", display: "flex", alignItems: "center", paddingLeft: "5px" }}>
                                    <div>
                                        <Stack direction="row" spacing={2}>
                                            <Avatar style={{ width: "35px", height: "35px" }} {...stringAvatar(chat?.contact?.name || chat?.contact?.verifiedName)} />
                                        </Stack>
                                    </div>
                                    <div style={{ display: "unset", paddingLeft: "5px" }}>
                                        <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontWeight: "550" }}>
                                            {chat?.contact?.name || chat?.contact?.verifiedName || chat?.id?.user}
                                            {chat?.contact?.isMe ? " (Me)" : ""}
                                        </div>
                                        <div>
                                            <em style={{ fontSize: "15px" }}>
                                                Test Message
                                            </em>
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                    <div style={{
                        height: "75vh",
                        width: "69vw",
                        border: "black solid 1px",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        display: "flex",
                        paddingTop: "6vh",
                        justifyContent: "center",
                        textAlign: "center"
                    }}>
                        <div >
                            <div style={{ width: "300px" }}>
                                <img src={whatsapp_placeholder} alt='' style={{ width: "100%" }}></img>
                                <h3>
                                    Click on a chat to view messages
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <ToastContainer />
        </>

    );
};

export default WhatsappMessages;
