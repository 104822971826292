import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { Tooltip } from '@mui/material';
const moment = require('moment');

const CustomToolbarSelect = ({ selectedRows, data, onRowsDelete, setSelectedRows, onEdit, setEditWhatsapp, setQueryModalTitle, toasts, setQuerid }) => {



    const handleDelete = () => {
        const rowsToDelete = selectedRows.data.map(row => data[row.index]);
        onRowsDelete(rowsToDelete, setSelectedRows);
    };

    const handleEdit = () => {
        if (selectedRows.data.length > 1) {
            alert('Please select only one row to edit');
            return false;
        }

        if (data[selectedRows.data[0].index]) {
            setQueryModalTitle(`Duplicating the query (${data[selectedRows.data[0].index].query_id}) with new QueryID`);
            setQuerid(data[selectedRows.data[0].index].query_id);
            setTimeout(() => {
                onEdit(data[selectedRows.data[0].index], "duplicate");
            }, 0);
        }
    };

    const handleEditWhatsappFun = () => {
        const now = moment();
        if (selectedRows.data.length > 1) {
            alert('Please select only one row to edit');
            return false;
        }
        if (data[selectedRows.data[0].index]) {
            setQueryModalTitle(`Editing the query (${data[selectedRows?.data[0]?.index]?.query_id}) keeping the same QueryID`);
            setQuerid(data[selectedRows.data[0].index].query_id);
            setTimeout(() => {
                setEditWhatsapp(true);
                onEdit(data[selectedRows.data[0].index], "same");
            }, 0);
        }
    }

    return (
        <div>
            <Tooltip title="Edit and update the same query (Keeps the same Query ID).">
                <IconButton onClick={handleEditWhatsappFun}>
                    <EditNoteIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Use this selected query data to generate a new query (Dupliacte the Query with new Query ID).">
                <IconButton onClick={handleEdit}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete the query.">
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default CustomToolbarSelect;
