// src/api/api.js
import axios from 'axios';
import { API_DOMAIN } from '../constants';

export const apiCall = async (value, endPoint, type = "post") => {
    try {
        const response = await axios[type](`${API_DOMAIN}/${endPoint}`, value);
        return response.data;
    } catch (error) {
        throw error;
    }
};
