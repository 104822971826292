// LoadingComponent.js
import React from 'react';

const LoadingComponent = ({ isLoading }) => {
    return (
        <div className="vY nq" style={{ display: isLoading ? 'block' : 'none' }}>
            <div className="vX UC">
                <div className="J-J5-Ji">
                    <div className="UD"></div>
                    <div className="vh">
                        <div className="J-J5-Ji">
                            <div className="vZ L4XNt"><span className="v1">Loading...</span></div>
                        </div>
                        <div className="J-J5-Ji"></div>
                    </div>
                    <div className="UB"></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingComponent;