import React from "react";
import wati_logo from "../../assets/WATI_logo_full.png";

function ChatHeader({ toggleChatbox, isChatOpen }) {
    return (
        <div className="chat-header" onClick={toggleChatbox}>
            <img src={wati_logo} style={{ width: "88px" }} />
        </div>
    );
}

export default ChatHeader;
