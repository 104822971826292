import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import LoginForm from '../components/Login/LoginForm';
import { motion } from 'framer-motion';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function Login() {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };


    useEffect(() => {

    }, []);

    return (
        <motion.div className='loginME'
            initial={{ width: 0 }}
            animate={{ width: '80vw' }}
            exit={{ width: '80vw', transition: { duration: 0.2 } }}
        >
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontSize: 20, fontFamily: 'Futura', textAlign: 'center', color: '#00C9FF' }} gutterBottom>
                            Enter your credentials
                        </Typography>
                        <LoginForm />
                    </Box>
                </Grid>


                <Grid item xs={12} md={6} sx={{}}>
                    <div style={{ marginTop: "60px" }}>
                        <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={3000} infinite={true}>

                            <div style={{ flex: '0 0 100%', maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="https://srm.uzdpart.com:33123/images/uzd_carousel/1.jpg" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }} />
                            </div>
                            <div style={{ flex: '0 0 100%', maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="https://srm.uzdpart.com:33123/images/uzd_carousel/2.jpg" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }} />
                            </div>
                            <div style={{ flex: '0 0 100%', maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="https://srm.uzdpart.com:33123/images/uzd_carousel/3.jpg" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }} />
                            </div>
                            <div style={{ flex: '0 0 100%', maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="https://srm.uzdpart.com:33123/images/uzd_carousel/4.jpg" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }} />
                            </div>
                            <div style={{ flex: '0 0 100%', maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="https://srm.uzdpart.com:33123/images/uzd_carousel/5.jpg" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }} />
                            </div>

                        </Carousel>

                    </div>
                </Grid>
            </Grid>
        </motion.div>
    );
};

export default Login;
